
import { Component } from 'vue-property-decorator';
import { acceptanceTemplatesPointsListHeaders } from '@/modules/acceptances/shared/config';
import ListComponent from '@/shared/components/layout/list/list-component';
import { mdiHome } from '@mdi/js';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { acceptancePointsCategoriesListHeaders } from '@/modules/acceptances/shared/config';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import AcceptancePointCategoryForm from '@/modules/acceptances/components/templates/points-categories/form.vue';
import { AcceptancePoint } from '@/modules/acceptances/shared/models/acceptance-point';
import { AcceptanceTemplatePointDataRequest } from '@/modules/acceptances/shared/requests/acceptance-template-point-data-request';
import {
    acceptancePointsCategoriesModule,
    acceptanceTemplatePointsModule,
} from '@/modules/acceptances/shared/state/submodules';
import PointDetails from './details.vue';
import { templatePointsListFilter } from '@/modules/acceptances/shared/config/filters';

@Component({
    components: {
        AcceptancePointCategoryForm,
        ItemsTable,
        PointDetails,
    },
})
export default class AcceptancePointsList extends ListComponent<AcceptancePoint, AcceptanceTemplatePointDataRequest> {
    public actionsTypes = acceptancePointsCategoriesModule.actionsTypes;
    public store = this.$store.state.acceptancesState.acceptancePointsCategoriesState;

    public headers: object[] = acceptancePointsCategoriesListHeaders;

    public mutationTypes = acceptancePointsCategoriesModule.mutationsTypes;
    public props: ModuleProps = acceptancePointsCategoriesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;

    public filters = templatePointsListFilter;

    public created() {
        this.fetchData();
    }
}
