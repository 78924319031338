
import { httpClient } from '@/shared/services';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/modules/admin/shared/models/user';
import { actionsTypes } from '@/modules/admin/shared/state';
import { MetaItem } from '@/shared/types';
import { acceptancesModule } from '../../shared/state/module';

@Component
export default class UserPicker extends Vue {
    @Prop(Object) public user!: User;
    @Prop(Number) public acceptanceId!: number;
    @Prop({ type: Boolean, default: false }) public small!: boolean;
    @Prop({ type: Boolean, default: false }) public xSmall!: boolean;
    @Prop({ type: Boolean, default: false }) public outlined!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public readOnly!: boolean;
    @Prop({ type: Boolean, default: false }) public getAssigment!: boolean;

    public menu = false;
    public search: string = '';
    public chosenOption: User | null = null;
    public loading = false;
    public timer: any = null;
    public currentPage = 1;
    public beforeLoadedChip = { name: 'Nieprzypisane', id: 0 };
    public mutationTypes = acceptancesModule.mutationsTypes;

    public fetchUsers() {
        this.$store.dispatch(actionsTypes.FETCH_USER_DATA, {
            simple: true,
            filters: { search: this.search },
            page: this.currentPage,
        });
    }

    get usersLoading() {
        return this.$store.state.adminState.loading;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get users() {
        return this.$store.state.adminState.users.filter(
            (user: User) => user.id !== this.currentUser.id || this.search,
        );
    }

    get meta(): MetaItem {
        return this.$store.state.adminState.meta;
    }

    public changeUser() {
        const acceptanceId = this.acceptanceId;
        if (this.getAssigment) {
            this.$emit(
                'assigment',
                httpClient.put(`/api/v1/acceptances/${acceptanceId}`, {
                    assigned_user_id: this.chosenOption ? this.chosenOption.id : null,
                }),
            );
            return;
        }
        this.menu = false;
        this.$emit('assigment', { loading: true, data: null });
        const that = this;
        httpClient
            .put(`/api/v1/acceptances/${acceptanceId}`, {
                assigned_user_id: this.chosenOption ? this.chosenOption.id : null,
            })
            .then(({ data }) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: `Osoba została przypisana do zadania`,
                });
                this.search = '';
                this.$store.commit(this.mutationTypes.UPDATE_CURRENT, data.data);
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            });
    }

    get activeUser() {
        return this.chosenOption ? this.chosenOption : this.user ? this.user : this.beforeLoadedChip;
    }

    get page() {
        return this.currentPage;
    }

    set page(val) {
        this.currentPage = val;
        this.fetchUsers();
    }

    @Watch('search')
    public OnSearchChange() {
        clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            this.page = 1;
        }, 300);
    }

    @Watch('menu')
    public OnMenuChange(value: boolean) {
        if (!value) {
            this.search = '';
        }
    }

    // public created() {
    //     if (this.acceptanceId) return;
    //     this.fetchUsers();
    // }
}
