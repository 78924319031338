
    import { Editor, EditorContent } from '@tiptap/vue-2';
    import StarterKit from '@tiptap/starter-kit';
    import { Color } from '@tiptap/extension-color';
    import Underline from '@tiptap/extension-underline';
    import Superscript from '@tiptap/extension-superscript';
    import Subscript from '@tiptap/extension-subscript';
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import WysiwygToolbar from './wysiwyg-toolbar/toolbar.vue';
    import { Prop, VModel, Watch } from 'vue-property-decorator';
    import { uniqueId } from 'lodash';
    import TextStyle from '@tiptap/extension-text-style';

    @Component({
        components: {
            EditorContent,
            WysiwygToolbar,
        },
    })
    export default class Wysiwyg extends Vue {
        @VModel({ type: String }) public content!: string;
        @Prop(String) public height!: string;
        @Prop({ type: Boolean, default: true }) public allowBlackCanvas!: boolean;
        @Prop({ type: Boolean, default: true }) public basicText!: boolean;
        @Prop({ type: Boolean, default: false }) public readOnly!: boolean;

        public uniqueId = uniqueId();

        public editor: Editor | null = null;

        public calcHeight = this.height || 0;

        get isDarkTheme() {
            return this.$vuetify.theme.dark;
        }

        get color() {
            if (this.isDarkTheme && this.allowBlackCanvas) {
                return 'grey';
            } else {
                return 'unset';
            }
        }

        public created() {
            this.editor = new Editor({
                content: this.content || '<p></p>',
                extensions: [StarterKit, Color, Underline, TextStyle, Subscript, Superscript],
                onUpdate: ({ editor }) => {
                    this.$set(this, 'content', editor.getHTML());
                },
            });

            this.editor.setEditable(!this.readOnly);
        }

        public mounted() {
            this.$nextTick(() => {
                this.setHeight();
            });
        }

        public beforeDestroy() {
            this.editor!.destroy();
        }

        public setHeight() {
            const container = document.getElementById(this.uniqueId);
            const editor = this.editor!.view.dom;

            const toolbarHeight = container!.querySelector('.editor__header')!.clientHeight;

            const calcHeight = `${container?.parentElement ? container.parentElement.clientHeight || 100 : 100}px`;
            const height = `calc(${this.height || calcHeight} - ${toolbarHeight}px)`;

            editor.style.minHeight = height;
            editor.style.maxHeight = height;
        }

        @Watch('readOnly', { immediate: true })
        public onReadOnlyChange(val: boolean) {
            if (!this.editor) {
                return;
            }
            this.editor.setEditable(!val);
        }
        @Watch('height', { immediate: true })
        public onHeightChange(val: string) {
            if (!this.editor) {
                return;
            }
            this.$nextTick(() => {
                this.setHeight();
            });
        }
    }
