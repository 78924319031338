
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {User} from '@/modules/admin/shared/models/user';
import {actionsTypes} from '@/modules/admin/shared/state';
import {MetaItem} from '@/shared/types';
import {ticketsModule} from '@/modules/tickets/shared';

@Component({
    props: {
        user: Object,
        small: {
            type: Boolean,
            default: false,
        },
        xSmall: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        ticketId: Number,

        disabled: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        }
    },
})
export default class UserPicker extends Vue {
    @Prop(Boolean) public getAssigment!: boolean;
    public menu = false;
    public categories = [];
    public user!: User;
    public search: string = '';
    public chosenOption: User | null = null;
    public loading = false;
    public timer: any = null;
    public currentPage = 1;
    public beforeLoadedChip = {name: 'Nieprzypisane', id: 0};
    public mutationTypes = ticketsModule.mutationsTypes;
    public actionTypes = ticketsModule.actionsTypes;

    public fetchUsers() {
        this.$store.dispatch(actionsTypes.FETCH_USER_DATA, {
            simple: true,
            filters: {search: this.search},
            page: this.currentPage,
        });
    }


    get usersLoading() {
        return this.$store.state.adminState.loading;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get users() {
        return this.$store.state.adminState.users.filter(
            (user: User) => user.id !== this.currentUser.id || this.search,
        );
    }

    get meta(): MetaItem {
        return this.$store.state.adminState.meta;
    }

    public changeUser() {
        const ticketId = this.$props.ticketId;
        this.search = '';
        this.$emit('assigment', {loading: true, data: null});

        if (this.getAssigment) {
            this.$emit(
                'assigment',
                this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {
                    id: ticketId,
                    assigned_user_id: this.chosenOption ? this.chosenOption.id : null,
                })
                    .then((response) => {
                        this.$store.commit('SHOW_SNACKBAR', {
                            title: 'Zadania',
                            type: 'success',
                            text: `Nowa osoba została przypisana do zadania`,
                        });
                        this.$store.commit(this.mutationTypes.UPDATE_CURRENT, response.data);
                        return response;
                    })
                    .catch((err) => {
                        this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
                    })
            );
            return;
        }
        this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {
          id: ticketId,
          assigned_user_id: this.chosenOption ? this.chosenOption.id : null,
        })
            .then((response) => {
              this.$store.commit('SHOW_SNACKBAR', {
                title: 'Zadania',
                type: 'success',
                text: `Nowa osoba została przypisana do zadania`,
              });
              this.$store.commit(this.mutationTypes.UPDATE_CURRENT, response.data);
              return response;
            })
            .catch((err) => {
              this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
            });
        this.menu = false;
    }

    get activeUser() {
        return this.chosenOption ? this.chosenOption : this.user ? this.user : this.beforeLoadedChip;
    }

    get page() {
        return this.currentPage;
    }

    set page(val) {
        this.currentPage = val;
        this.fetchUsers();
    }

    @Watch('search')
    public OnSearchChange() {
        clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            this.page = 1;
        }, 300);
    }

    @Watch('menu')
    public OnMenuChange(value: boolean) {
        if (!value) {
            this.search = '';
        }
    }

    // public created() {
    //     if (this.ticketId) return;
    //     this.fetchUsers();
    // }
}
