
import {Component, Prop, Vue, VModel, Watch} from 'vue-property-decorator';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { httpClient } from '@/shared/services';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { ModuleState } from '@/shared/state/template/module-state';

@Component({
    components: {TextContrast, IconContrast, NameReduce},
})
export default class ObjectPicker extends Vue {
    @VModel({type: undefined}) public itemValue!: any;
    @Prop(Object) public state!: ModuleState<any, any>;
    @Prop(Boolean) public removedItem!: boolean;

    public search: string = '';
    public prevsearch: string = '';
    public selectedItem: any = {};
    public menu = true;
    public items = [];
    public timer: any = null;
    public isLoading: boolean = false;
    public isSelectedItemInitialized = false;

    get pickerLoading() {
        return this.isLoading;
    }

    get objects() {
        const pickerData = this.items;
        return pickerData;
    }

    set objects(data) {
        this.items = data;
    }

    public created() {
        this.selectedItem = this.removedItem ? {name: '[Usunięty obiekt]'} : this.itemValue;
        if (this.selectedItem === null) {
            this.fetchData();
        }
        this.isSelectedItemInitialized = true;
    }

    public getIconBasedOnType(type: string) {
        switch (type) {
            case 'investment': return 'mdi-hand-coin-outline';
            case 'investment-object': return 'mdi-city';
            default: return '';
        }
    }

    public fetchData() {
        let filters = '?search=' + this.search;
        if (this.selectedItem && this.selectedItem.root_parent
            && this.selectedItem.root_parent.type === 'investment') {
                filters = filters + '&investment_id=' + this.selectedItem.root_parent.id;
        }
        if (this.selectedItem && ((this.selectedItem.parent && this.selectedItem.parent.id
            && this.selectedItem.parent.type === 'investment-object')
            || this.selectedItem.type === 'investment-object')) {
                filters = filters + '&object_id=' +
                    (this.selectedItem.parent && this.selectedItem.parent.id ? this.selectedItem.parent.id
                            : this.selectedItem.id);
            }

        this.isLoading = true;
        httpClient.get(`api/v1/investments/picker-data` + filters)
            .then((data: any) => {
                this.objects = data.data;
                this.isLoading = false;
            }).catch((e: any) => {
                this.isLoading = false;
            });
    }

    /** Fixes vuetify bug that sets selectedItem to null when user try to erase last character */
    public onFieldDelete(event: KeyboardEvent) {
        if (this.search.length === 1) {
            event.preventDefault();
            event.stopPropagation();
            this.search = '';
        }
    }

    public onPicked() {
        if ((!this.selectedItem || (this.selectedItem && this.selectedItem.type !== 'investment-subject'))
            && this.isSelectedItemInitialized) {
            clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
                this.fetchData();
            }, 1000);
        }
    }

    public deleteSegment() {
        if (this.selectedItem && this.selectedItem.parent && this.selectedItem.parent.id) {
            this.selectedItem = {
                root_parent: this.selectedItem.root_parent,
                id: this.selectedItem.parent.id,
                name: this.selectedItem.parent.name,
                type: this.selectedItem.parent.type,
                parent: this.selectedItem.parent.has_parent ? {fake: true} : null,
            };
        } else if (this.selectedItem && this.selectedItem.root_parent
            && (!this.selectedItem.parent || (this.selectedItem.parent && this.selectedItem.parent.fake))) {
            this.selectedItem = {
                id: this.selectedItem.root_parent.id,
                name: this.selectedItem.root_parent.name,
                type: this.selectedItem.root_parent.type,
            };
        } else {
            this.selectedItem = null;
        }
    }

    @Watch('search')
    public onSearch(newVal: string, oldVal: string) {
        clearTimeout(this.timer);
        this.prevsearch = this.search;
        this.search = newVal ?? '';

        this.timer = window.setTimeout(() => {
            this.fetchData();
        }, 800);
    }

    @Watch('selectedItem')
    public onItemSelect(nevVal: any, oldVal: any) {
        if (nevVal !== oldVal && this.isSelectedItemInitialized) {
            this.onPicked();
            this.assignValue(this.selectedItem);
        }
    }

    private assignValue(val: any) {
        const preparedVal = val ? {
            type: val.type ?? null,
            id: val.id ?? null,
        } : null;
        this.$set(this, 'itemValue', val && val.id ? preparedVal : null);
        this.$emit('input', val && val.id ? preparedVal : null);
    }
}

