
import { Component, Prop, Vue } from 'vue-property-decorator';
import { contrast, hexToRgb } from '@/shared/helpers/colors';

@Component({
    props: {
        close: Boolean,
        disabled: Boolean,
        small: Boolean,
        color: String,
    },
})
export default class ChipColor extends Vue {
    @Prop({ type: Boolean, default: false }) public small!: boolean;
    @Prop({ type: Boolean, default: false }) public outlined!: boolean;
    @Prop({ type: Boolean, default: false }) public disable!: boolean;
    @Prop({ type: Boolean, default: false }) public close!: boolean;
    @Prop(String) public color!: string;
    public whiteColor = hexToRgb('#ffffff');
    public blackColor = hexToRgb('#000000');

    public tagStyle: object = {
        maxWidth: !this.small ? '300px' : '150px',
    };

    get isDark() {
        return contrast(hexToRgb(this.color), this.whiteColor) > 2;
    }

    get isLight() {
        return contrast(hexToRgb(this.color), this.blackColor) > 2;
    }
}
