

import DetailsComponent from '@/shared/components/layout/details/details-component';
import {CalendarEvent} from '@/modules/calendar/shared/models/calendar-event';
import {calendarModule} from '../shared/state/module';
import {Component, Prop} from 'vue-property-decorator';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import {eventAssignables} from '@/shared/config/calendar';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

@Component({
    components: {
        NameReduce,
        TextContrast,
    }
})
export default class EventDetails extends DetailsComponent<CalendarEvent> {
    @Prop(Boolean) public hideButtons!: boolean;
    public actionsTypes = calendarModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public store = this.$store.state.calendarState;

    public created() {
        //
    }

    get assignableRoute() {
        return this.eventAssignable ? {
            name: this.eventAssignable.route,
            params: {
                id: this.item.assignable_id
            }
        } : null;
    }

    get eventAssignable() {
        return eventAssignables.find((assignable) => assignable.type === this.item.assignable_type);
    }
}

