
    import { Editor } from '@tiptap/vue-2';
    import Vue from 'vue';
    import { Component, Prop, VModel } from 'vue-property-decorator';

    @Component
    export default class FontColorPicker extends Vue {
        @VModel() public showColorsMenu!: boolean;
        @Prop({
            type: Object,
            required: true,
        })
        public editor!: Editor;
        @Prop({
            type: String,
            required: true,
        })
        public icon!: string;
        @Prop({
            type: String,
            required: true,
        })
        public title!: string;
        @Prop({
            type: Function,
            required: true,
        })
        public action!: string;
        @Prop({
            type: Function,
            default: () => true,
        })
        public isActive!: () => boolean;
        @Prop(Boolean) public readOnly!: boolean;

        public swatches = [
            ['#FF0000', '#AA0000', '#550000'],
            ['#FFFF00', '#AAAA00', '#555500'],
            ['#00FF00', '#00AA00', '#005500'],
            ['#00FFFF', '#00AAAA', '#005555'],
            ['#0000FF', '#0000AA', '#000055'],
        ];

        public changeFontColor(color: any) {
            if (color === null) {
                this.editor.chain().focus().unsetColor().run();
            } else {
                this.editor.chain().focus().setColor(color.hex).run();
            }
        }
    }
