
import { Component, Inject, Watch } from 'vue-property-decorator';
import { mdiEmail, mdiPhone } from '@mdi/js';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    acceptanceTemplatePointsModule,
    acceptanceTemplatesModule,
    acceptancePointsCategoriesModule,
    acceptanceTemplateListModule,
} from '@/modules/acceptances/shared/state/submodules';

import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import AcceptanceTemplatePointForm from '@/modules/acceptances/components/templates/points/form.vue';
import AcceptanceCategoryForm from '@/modules/acceptances/components/templates/points-categories/form.vue';
import { FormRules } from '@/shared/validation/form-rules';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { AcceptanceTemplate } from '@/modules/acceptances/shared/models/acceptance-template';
import draggable from 'vuedraggable';
import PreventUnload from '@/shared/PreventUnload.vue';
import { AcceptanceTemplatePoint } from '@/modules/acceptances/shared/models/acceptance-template-point';
import _, { findIndex } from 'lodash';
import FilterSelect from '@/shared/components/layout/list/filter-bar/filter-select.vue';
import { AcceptancePointCategory } from '../../shared/models/acceptance-point-category';
import TemplateForm from './form.vue';
import ListForm from '@/modules/acceptances/components/templates/lists/form.vue';
import { AcceptanceTemplateList } from '@/modules/acceptances/shared/models/acceptance-template-list';
import TabSelector from '@/modules/acceptances/components/tab-selector.vue';
import AcceptanceTemplatesList from './list.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    props: {
        id: [String, Number],
    },
    components: {
        PreventUnload,
        AutoCompleteWithAddNew,
        NameReduce,
        TopToolbar,
        draggable,
        FilterSelect,
        TemplateForm,
        ListForm,
        TabSelector,
        TooltipBtn,
    },
})
export default class AcceptanceTemplateDetails extends DetailsComponent<AcceptanceTemplate> {
    public filters = {};
    public icons: object = {
        mdiPhone,
        mdiEmail,
    };
    public props: ModuleProps = acceptanceTemplatesModule.moduleProps;
    public actionsTypes = acceptanceTemplatesModule.actionsTypes;
    public templateListActionTypes = acceptanceTemplateListModule.actionsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public itemData: AcceptanceTemplatePoint = {} as AcceptanceTemplatePoint;
    public valid = false;
    public updatingItem = false;
    public formRules = new FormRules();
    public TemplatePointForm = AcceptanceTemplatePointForm;
    public CategoryForm = AcceptanceCategoryForm;
    public templatePointsModule = acceptanceTemplatePointsModule;
    public templatePointsState = this.$store.state.acceptancesState.templatePointsState;
    public pointsCategoriesModule = acceptancePointsCategoriesModule;
    public pointsCategoriesState = this.$store.state.acceptancesState.acceptancePointsCategoriesState;
    public templateListState = this.$store.state.acceptancesState.acceptanceTemplateListState;
    public store = this.$store.state.acceptancesState.templatesState;
    public selectedCategory: AcceptancePointCategory = {} as AcceptancePointCategory;
    public pointItemTemplate = {} as any;
    public categoriesListValues: any = [];
    public showForm = false;
    public showListForm = false;
    public showTemplateForm = false;
    public currentList = 0;
    public elementToScrollTo: any = null;
    public listsLoading = true;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public listItemData: AcceptanceTemplateList = {
        acceptance_template_id: this.$props.id,
        color: '#cccccc',
        is_global: false,
    } as AcceptanceTemplateList;

    get isButtonDisabled() {
        return !this.valid || this._.isEmpty(this.itemData);
    }

    get categoriesInUse() {
        if (!this.item || !this.lists || !this.lists[this.currentList]) {
            return [];
        } else {
            return this.lists[this.currentList].point_categories;
        }
    }

    get lists() {
        return this.templateListState.data;
    }

    set lists(value: any) {
        this.templateListState.data = value;
    }

    public created() {
        this.fetchDetails();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`acceptances.templates.${action}`);
    }

    public fetchLists(cb?: (data: any) => void) {
        this.lists = [];
        this.listsLoading = true;
        this.$store.dispatch(this.templateListActionTypes.FETCH_DATA, { id: this.id }).then((data) => {
            if (cb) {
                cb(data);
            }
            this.listsLoading = false;
        });
    }

    public addNewList() {
        this.listItemData = {
            acceptance_template_id: Number(this.id),
            color: '#cccccc',
            is_global: false,
        } as AcceptanceTemplateList;

        this.$nextTick(() => {
            this.showForm = true;
            this.showListForm = true;
        });
    }

    public editList(item: AcceptanceTemplateList) {
        this.listItemData = JSON.parse(JSON.stringify(item));
        this.showForm = true;
        this.showListForm = true;
    }

    public setListAsGlobal({ id }: AcceptanceTemplatesList) {
        const newLists = this.lists.map((el: AcceptanceTemplateList) => {
            return { ...el, is_global: el.id === id };
        });
        this.$nextTick(() => {
            this.lists = newLists;
        });
        this.$store.dispatch(this.templateListActionTypes.UPDATE_ITEM, {
            acceptance_template_id: this.id,
            id,
            is_global: true,
        });
    }

    public onNewListSaved({ data }: any) {
        this.showForm = false;
        this.showListForm = false;
        this.fetchLists((res: any) => {
            const index = res.data.findIndex((el: any) => el.id === data.id);
            this.currentList = index >= 0 ? index : 0;
            this.categoriesListValues.push([]);
        });
    }

    public addPoint() {
        const category = this.lists[this.currentList].point_categories.find(
            (el: AcceptancePointCategory) => this.itemData.acceptance_point_category.id === el.id,
        );

        if (!category) {
            this.lists[this.currentList].point_categories.push(this.itemData.acceptance_point_category);
        }

        this.elementToScrollTo = `category-${this.itemData.acceptance_point_category_id}`;
        this.$nextTick(() => {
            if (!!this.elementToScrollTo) {
                const element = this.$refs[this.elementToScrollTo] as any;
                if (!element || !element[0]) {
                    return;
                }
                this.elementToScrollTo = '';
                this.$nextTick(() => {
                    setTimeout(() => {
                        const offset = 80;
                        this.$vuetify.goTo(element[0], { duration: 600, easing: 'easeInCubic', offset }).then(() => {
                            const categoryListItemIndex = this.lists[this.currentList].point_categories.findIndex(
                                (el: AcceptancePointCategory) => el.id === this.itemData.acceptance_point_category.id,
                            );
                            if (
                                this.itemData.acceptance_point_category_id &&
                                !this.categoriesListValues[this.currentList][categoryListItemIndex]
                            ) {
                                this.categoriesListValues[this.currentList][categoryListItemIndex] = true;
                            }
                            if (_.isEmpty(this.itemData)) {
                                return;
                            }
                            this.lists[this.currentList].points.unshift(this.itemData);
                            this.itemData = {} as AcceptanceTemplatePoint;
                            this.savePoints();
                        });
                    }, 100);
                });
            }
        });
    }

    public pointUsed(item: AcceptanceTemplatePoint) {
        return !!this.lists[this.currentList].points.find((point: AcceptanceTemplatePoint) => point.id === item.id);
    }

    @Watch('selectedCategory')
    public onSelectedCategoryChange(item: AcceptancePointCategory) {
        this.pointItemTemplate.acceptance_point_category_id = item?.id || 0;
        this.pointItemTemplate.acceptance_point_category = item;
    }

    @Watch('categoriesInUse', { deep: true })
    public onCategoriesChange(newVal: any, oldVal: any) {
        if (!newVal || !newVal.length || !this.categoriesListValues[this.currentList]) {
            return;
        }
        if (!this.categoriesListValues[this.currentList][newVal.length - 1]) {
            this.categoriesListValues[this.currentList][newVal.length - 1] = true;
        }
    }

    public categoryPoints(category: number) {
        return this.lists[this.currentList].points.filter(
            (e: AcceptanceTemplatePoint) => e.acceptance_point_category_id === category,
        );
    }

    public removePoint(point: AcceptanceTemplatePoint) {
        if (
            point.acceptance_point_category_id > 0 &&
            this.categoryPoints(point.acceptance_point_category_id).length - 1 < 1
        ) {
            const categoryIndex = this.lists[this.currentList].point_categories.findIndex(
                (category: AcceptancePointCategory) => category.id === point.acceptance_point_category_id,
            );
            this.$delete(this.lists[this.currentList].point_categories, categoryIndex);
        }
        const index = this.lists[this.currentList].points.findIndex(
            (item: AcceptanceTemplateList) => point.id === item.id,
        );
        this.$delete(this.lists[this.currentList].points, index);
        this.savePoints();
    }

    public savePoints() {
        this.updatingItem = true;
        this.$store
            .dispatch(this.templateListActionTypes.UPDATE_ITEM, {
                id: this.lists[this.currentList].id,
                acceptance_template_id: this.id,
                points: this.lists[this.currentList].points.map(({ id }: AcceptanceTemplatePoint) => id),
            })
            .then((response) => {
                if (response) {
                    this.updatingItem = false;
                }
            });
    }

    public fetchCallback(response: ModuleShowPayload<AcceptanceTemplate>) {
        this.fetchLists(() => {
            this.lists.forEach((el: any) => {
                this.categoriesListValues.push([]);
            });

            this.lists[this.currentList].point_categories.forEach((el: any, index: number) => {
                this.categoriesListValues[this.currentList][index] = true;
            });
            this.$forceUpdate();
        });
    }

    @Watch('selectedCategory')
    public onCategoryChange(val: AcceptancePointCategory) {
        if (_.isEmpty(val)) {
            this.filters = {};
            return;
        }

        this.filters = { acceptance_point_category_id: val.id };
    }
}
