
import {User} from '@/modules/admin/shared/models/user';
import {TicketFormProperties} from '@/modules/tickets/components/form/properties';

export class TicketFormGetters extends TicketFormProperties {
    get priorities() {
        return this.prioritiesState.data;
    }

    get prioritiesLoading() {
        return this.prioritiesState.loading;
    }

    get statuses() {
        return this.statusesState.data;
    }

    get statusesLoading() {
        return this.statusesState.loading;
    }

    get tagsLoading() {
        return this.tagsState.loading;
    }

    get tagLoading() {
        return this.tagsState.loadingItem;
    }

    get tags() {
        return this.tagsState.data;
    }

    get tagEdited() {
        return this.tagsState.editedItem;
    }

    set tagEdited(value) {
        this.$store.commit(this.tagsModule.mutationsTypes.UPDATE_EDITED, value);
    }

    get usersLoading() {
        return this.$store.state.adminState.loadingList;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get users() {
        return [
            this.currentUser,
            ...this.$store.state.adminState.users.filter((user: User) => user.id !== this.currentUser.id),
        ];
    }
}
