
import { hexToRgb, contrast } from '@/shared/helpers/colors';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import {ModuleState} from '@/shared/state/template/module-state';
import { AcceptanceNote } from '../shared/models/acceptance-note';
import { AcceptanceNoteDataRequest } from '../shared/requests/acceptance-note-data-request';


@Component({})
export default class TabSelector extends Vue {
    @Prop(Array) public items!: any;
    @Prop(Number) public value!: number;
    @Prop(String) public removeAction!: string;
    @Prop(Boolean) public allowEdit!: boolean;
    @Prop(Boolean) public loading!: boolean;
    @Prop(Boolean) public showProgress!: boolean;
    @Prop(Boolean) public isTemplate!: boolean;
    @Prop(Boolean) public showNotesCount!: boolean;

        public notesStore: ModuleState<AcceptanceNote, AcceptanceNoteDataRequest>
    = this.$store.state.acceptancesState.acceptanceNotesState;

    get notes() {
        return this.$store.state.acceptancesState.notes;
    }
    get isElementGlobal() {
        return this.rightClickedElementData?.is_global;
    }

    set isElementGlobal(val: any) {
        this.setGlobal();
    }

    get currentGlobal() {
        if (!this.$props.items) {
            return 0;
        } else {
            return this.$props.items.find((el: any) => el.is_global)?.id || 0;
        }
    }

    get currentTab() {
        return this.$props.value;
    }

    set currentTab(val) {
        if (val > this.$props.items.length - 1) {
            return;
        }
        this.$emit('input', val || 0);
    }

    public rightClickedElementData: any = {};
    public showMenu = false;
    public x = 0;
    public y = 0;
    public tabsKey = 0;
    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');


    public getProgress(points: any[]) {
        if (!points) { return 0; }
        const allPoints = points.length;
        const checkedPoints = points.filter((el) => el.checked).length;
        return `${checkedPoints} / ${allPoints}`;
    }
    public isDone(points: any[]) {
        return points.filter((el) => el.checked).length === points.length;
    }

    public emitEdit() {
        this.$emit('edit', this.rightClickedElementData);
    }

    public getNumberOfActiveNotes(id: number) {
        return (this.notes.filter((el: any) => el.acceptance_list_id === id && el.ticket.status.is_open)).length;
    }

    public handleAddNew() {
        const tabToBackTo = this.currentTab;
        this.tabsKey++;

        this.$nextTick(() => {
            this.$emit('add');
            this.$emit('input', tabToBackTo);
        });
    }

    public setGlobal() {
        this.$emit('setAsGlobal', this.rightClickedElementData);
        this.$nextTick(() => {
            this.showMenu = false;
            this.$forceUpdate();
        });
    }

    public displayMenu(e: any, list: any) {
        e.preventDefault();
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.rightClickedElementData = list;
        this.$nextTick(() => {
            this.showMenu = true;
        });
    }

    // get progress() {
    //     this.$props.items[0].acceptance_point;
    //     return [];
    // }

    // @Watch('value')
    // public onVModelValueChange() {
    //     this.$forceUpdate();
    // }

    // @Watch('currentTab')
    // public onValueChange(val: any) {
    //     if (this.currentTab > this.$props.items.length - 1) {
    //         return;
    //     }
    //     this.$emit('input', val || 0);
    // }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }

    public deleteRightClickedElement() {
        if (!this.isElementGlobal) {
        this.$store
            .dispatch(this.$props.removeAction, this.rightClickedElementData.id)
            .catch((err) => {
                if (err) {
                    this.$store.commit('SHOW_SNACKBAR', {
                        type: 'error',
                        text: 'Coś poszło nie tak',
                    });
                }
            })
            .finally(() => {
                this.$emit('delete', this.rightClickedElementData);
            });
    }}
}
