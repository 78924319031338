import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';

export const getNextCode = (rooms: InvestmentSubjectRoom[]) => {
    if (rooms.length < 1) {
        return 1;
    } else {
        const codes = rooms.map(({code}: InvestmentSubjectRoom) => code);
        const lastCode = Math.max(...codes);
        return ((lastCode || 0) + 1);
    }
};
