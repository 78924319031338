
import {Component, Vue, Watch} from 'vue-property-decorator';
import {sortItemsTree} from '@/shared/helpers';
import {SelectListItem} from '@/shared/models/select-list-item';

@Component({
    props: {
        label: String,
        options: Array,
        rules: Array,
        disabled: Boolean,
        itemText: {
            type: String,
            default: 'name',
        },
        itemValue: {
            type: String,
            default: 'id',
        },
        chips: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        value: {
            type: [Object, Number, Array],
        },
        hideDetails: {
            type: Boolean,
            default: false,
        }
    },
})
export default class SelectTree extends Vue {
    public field!: HTMLInputElement;
    public multiple!: boolean;
    public options!: SelectListItem[];
    public value!: any;

    public mounted() {
        this.field = this.$refs.field as HTMLInputElement;
    }

    get items() {
        return sortItemsTree(this.options);
    }

    public selectItem(item: any) {
        if (!this.multiple) {
            this.selectedItems = item;
        } else {
            this.selectedItems.push(item);
        }
        this.field.blur();
    }

    get selectedItems() {
        return this.value;
    }

    set selectedItems(value) {
        this.$emit('input', value);
    }
}
