
import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import Konva from 'konva';
import {KonvaEventObject} from 'konva/lib/Node';
import {StageConfig} from 'konva/lib/Stage';
import {ModuleState} from '@/shared/state/template/module-state';
import {
    InvestmentSubjectRoomDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-room-data-request';
import {
    InvestmentSubjectPlanDataRequest
} from '@/modules/investments/shared/requests/investment-subject-plan-data-request';
import {InvestmentSubjectPlan} from '@/modules/investments/shared/models/investment-subject-plan';
import {
    investmentSubjectPlansModule,
    investmentSubjectRoomsModule
} from '@/modules/investments/shared/state/submodules';
import {Prop} from 'vue-property-decorator';
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';
import {InvestmentSubjectRoomsState} from '@/modules/investments/shared/state/state/rooms';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import {getNextCode} from '@/modules/investments/shared/helpers/rooms';

@Component({
    components: {
        ActionConfirmDialog,
    },
    props: {
        itemData: Object,
    },

})
export default class StoreyPlan extends Vue {
    @Prop(Number) public subjectId!: number;
    public canvasWidth = window.innerWidth;
    public canvasHeight = window.innerHeight;
    public canvasScale = 1;
    public backgroundLoading = false;
    public scaleBy = 0.79;
    public itemData!: InvestmentSubjectRoomDataRequest;
    public storeyPlanNumber!: InvestmentSubjectRoomDataRequest;
    public store: ModuleState<InvestmentSubjectPlan,
        InvestmentSubjectPlanDataRequest>
        = this.$store.state.investmentsState.subjectPlansState;
    public mutationTypes = investmentSubjectPlansModule.mutationsTypes;
    public roomsActions = investmentSubjectRoomsModule.actionsTypes;
    public roomsState: InvestmentSubjectRoomsState = this.$store.state.investmentsState.subjectRoomsState;
    public background: HTMLImageElement = new Image();
    public roomsMutations = investmentSubjectRoomsModule.mutationsTypes;
    public lastCenter: { x: number, y: number } | null = null;
    public lastDistance: number | null = null;
    public roomsList: boolean = false;
    public avoidFormDialog: boolean = false;
    public cursorX: number = 0;
    public cursorY: number = 0;

    get subject() {
        return this.$store.state.investmentsState.subjectsState.current;
    }

    public closeDialog() {
        this.$emit('closed');
    }

    get storeys() {
        return this.store.data.map(({storey}: InvestmentSubjectPlan) => storey);
    }

    get minStorey() {
        return Math.min(...this.storeys);
    }

    get maxStorey() {
        return Math.max(...this.storeys);
    }

    get pointSelecting() {
        return this.roomsState.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.roomsMutations.UPDATE_POINT_SELECTING, value);
    }

    get roomsLoading() {
        return this.$store.state.investmentsState.subjectRoomsState.loadingList;
    }

    public selectEditRoom(room: InvestmentSubjectRoom) {
        this.avoidFormDialog = true;
        this.pointSelecting = true;
        this.editedRoom = Object.assign({}, room);
    }

    public getCenter(p1: { x: number, y: number }, p2: { x: number, y: number }) {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    public getDistance(p1: { x: number, y: number }, p2: { x: number, y: number }) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    public createRoom() {
        this.editedRoom = Object.assign({}, {
            investment_subject_id: this.currentPlan.investment_subject_id,
            storey: this.currentPlan.storey,
        });

        this.$set(this.editedRoom, 'code', getNextCode(this.roomsState.dataList as InvestmentSubjectRoom[]));
        this.pointSelecting = true;
        // this.roomsState.formDialog = true;
    }

    public editRoom(item: InvestmentSubjectRoom) {
        this.editedRoom = Object.assign({}, item);
        this.roomsState.formDialog = true;
        this.pointSelecting = true;
    }


    public cursorMove(event: KonvaEventObject<MouseEvent>) {
        if (this.pointSelecting) {
            const position = this.stage.getPointerPosition();
            if (position) {
                const cursorX = (position.x - this.stage.x()) / this.canvasScale;
                const cursorY = (position.y - this.stage.y()) / this.canvasScale;
                if (cursorX >= 0 && cursorX <= this.background.width) {
                    this.cursorX = cursorX;
                }
                if (cursorY >= 0 && cursorY <= this.background.height) {
                    this.cursorY = cursorY;
                }
            }
        }
    }

    public deleteRoom(item: InvestmentSubjectRoom) {
        this.roomsState.current = Object.assign({}, item);
        this.roomsState.removeDialog = true;
    }

    get currentPlan() {
        return this.store && this.store.current ?
            this.store.current as InvestmentSubjectPlan :
            {} as InvestmentSubjectPlan;
    }

    set currentPlan(value) {
        this.$store.commit(this.mutationTypes.UPDATE_CURRENT, value);
    }

    get subjects() {
        return this.$store.state.investmentsState.subjectPlansState;
    }

    get editedRoom() {
        return this.$store.state.investmentsState.subjectRoomsState.editedItem;
    }

    set editedRoom(val) {
        this.$store.commit(this.roomsMutations.UPDATE_EDITED, val);
    }

    get stageConfig() {
        return {
            width: this.canvasWidth,
            height: this.canvasHeight,
            draggable: true,
            scale: {x: this.canvasScale, y: this.canvasScale},
        } as StageConfig;
    }

    public selectPoint(e: KonvaEventObject<TouchEvent>) {
        if (this.pointSelecting && this.stage && this.stage.pointerPos) {
            if (!e.evt.touches || (e.evt.touches && e.evt.touches.length < 2)) {
                const x = ((this.stage.pointerPos.x - this.stage.x()) / this.canvasScale);
                const y = ((this.stage.pointerPos.y - this.stage.y()) / this.canvasScale);
                this.$set(this.editedRoom, 'x', this._.round(x));
                this.$set(this.editedRoom, 'y', this._.round(y));
                if (this.avoidFormDialog) {
                    this.pointSelecting = false;
                    this.$store.dispatch(this.roomsActions.UPDATE_ITEM, this.editedRoom).then((response) => {
                        const roomIndex = this.roomsState.dataList.map((room) => room.id).indexOf(response.data.id);
                        if (roomIndex !== -1) {
                            this.$set(this.roomsState.dataList, roomIndex, response.data);
                        } else {
                            this.roomsState.dataList.push(response.data);
                        }
                        this.avoidFormDialog = false;
                        this.editedRoom = {} as InvestmentSubjectRoom;
                    });
                } else {
                    this.roomsState.formDialog = true;
                }
            }
        }
    }

    get stage(): Konva.Stage {
        const stageComponent: any = this.$refs.stage;
        return stageComponent ? stageComponent.getStage() : null;
    }

    public zoom(e: KonvaEventObject<WheelEvent>, zoom: boolean = true) {
        e.evt.preventDefault();
        const pointer = this.stage.getPointerPosition();
        if (
            pointer &&
            (e.evt.deltaY > 0 || this.canvasScale <= (this.background.width / this.canvasWidth) * 5) &&
            (e.evt.deltaY < 0 || this.canvasScale >= (this.background.width / this.canvasWidth) / 2)
        ) {
            const oldScale = Number(this.canvasScale);

            const mousePointTo = {
                x: (pointer.x - this.stage.x()) / oldScale,
                y: (pointer.y - this.stage.y()) / oldScale,
            };

            if (zoom) {
                this.canvasScale = e.evt.deltaY > 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
            }

            const newPos = {
                x: pointer.x - mousePointTo.x * this.canvasScale,
                y: pointer.y - mousePointTo.y * this.canvasScale,
            };
            this.stage.position(newPos);
            this.stage.batchDraw();
        }
    }

    public mounted() {
        this.$store.dispatch(this.roomsActions.FETCH_DATA, {simple: true, id: this.subjectId});

        Konva.hitOnDragEnabled = true;
        this.changeBackground();
        window.addEventListener('resize', () => {
            this.canvasWidth = window.innerWidth;
            this.canvasHeight = window.innerHeight;
        });
    }

    public changeBackground() {
        const background = new Image();
        background.src = this.currentPlan.media_file.url;
        this.backgroundLoading = true;
        background.onload = () => {
            this.backgroundLoading = false;
            let newPos;
            if (this.canvasWidth > this.canvasHeight || this.background.width < this.background.height) {
                newPos = {
                    x: (this.canvasWidth / 2 - background.width / 2) * this.canvasScale,
                    y: 0,
                };
            } else {
                newPos = {
                    x: 0,
                    y: 0,
                };
            }
            this.stage.position(newPos);
            this.canvasScale = this.canvasHeight / background.height;
            this.background = background;
        };
    }

    public touchZoom(e: KonvaEventObject<TouchEvent>) {
        e.evt.preventDefault();
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
            // if the stage was under Konva's drag&drop
            // we need to stop it, and implement our own pan logic with two pointers
            if (this.stage.isDragging()) {
                this.stage.stopDrag();
            }

            const p1 = {
                x: touch1.clientX,
                y: touch1.clientY,
            };
            const p2 = {
                x: touch2.clientX,
                y: touch2.clientY,
            };

            if (!this.lastCenter) {
                this.lastCenter = this.getCenter(p1, p2);
                return;
            }
            const newCenter = this.getCenter(p1, p2);

            const dist = this.getDistance(p1, p2);

            if (!this.lastDistance) {
                this.lastDistance = dist;
            }

            const pointTo = {
                x: (newCenter.x - this.stage.x()) / this.canvasScale,
                y: (newCenter.y - this.stage.y()) / this.canvasScale,
            };

            this.canvasScale = this.canvasScale * (dist / this.lastDistance);


            // calculate new position of the stage
            const dx = newCenter.x - this.lastCenter.x;
            const dy = newCenter.y - this.lastCenter.y;

            const newPos = {
                x: newCenter.x - pointTo.x * this.canvasScale + dx,
                y: newCenter.y - pointTo.y * this.canvasScale + dy,
            };

            this.stage.position(newPos);

            this.lastDistance = dist;
            this.lastCenter = newCenter;
        }
    }

    get roomPoints(): Konva.PathConfig[] {
        return !this.backgroundLoading && this.rooms
            ? this.rooms
                .filter((room) => room.id !== this.editedRoom.id)
                .map(({x, y}: InvestmentSubjectRoom, index: number) => this.planMarker(x, y))
            : [];
    }

    get roomTexts(): Konva.PathConfig[] {
        return !this.backgroundLoading && this.rooms
            ? this.rooms
                .filter((room) => room.id !== this.editedRoom.id)
                .map(
                    (room: InvestmentSubjectRoom) => this.planText(
                        room.x,
                        room.y,
                        String(room.code),
                    )
                )
            : [];
    }

    get rooms() {
        const rooms = this.roomsState.dataList as InvestmentSubjectRoom[];
        return rooms
            .filter(
                (room: InvestmentSubjectRoom) => room.storey === this.currentPlan.storey
            )
            .sort(
                (a, b) => Number(a.code) - Number(b.code)
            );
    }

    public newPlan(storey: number) {
        return this.store.data.find(
            (plan: InvestmentSubjectPlan) => this.currentPlan.storey + storey === plan.storey
        );
    }

    public changePlan(storey: number) {
        const newPlan = this.newPlan(storey);
        if (newPlan) {
            this.currentPlan = newPlan;
            this.changeBackground();
        }
    }

    get newPoint() {
        return this.planMarker(
            this.cursorX,
            this.cursorY,
            true
        );
    }

    get newPointText() {
        return this.planText(
            this.cursorX,
            this.cursorY,
            String(this.editedRoom.code),
        );
    }


    public planMarker(x: number, y: number, edit: boolean = false) {
        return {
            x,
            y,
            fill: edit ? '#4CAF50' : 'red',
            strokeWidth: 0.5,
            stroke: '#777',
            opacity: 1,
            scale: {
                x: ((this.background.height / this.canvasHeight) ) * 2,
                y: ((this.background.height / this.canvasHeight) ) * 2,
            },
            radius: 12,
        } as Konva.CircleConfig;
    }

    public planText(x: number, y: number, text: string) {
        return {
            x,
            y,
            offsetX: text ? text.length * 2.75 : 0,
            offsetY: 4.5,
            text: text !== 'null' ? text : '',
            fill: 'white',
            align: 'center',
            fontSize: 10,
            verticalAlign: 'center',
            scale: {
                x: ((this.background.height / this.canvasHeight)) * 2,
                y: ((this.background.height / this.canvasHeight)) * 2,
            },
            strokeWidth: 0,
            opacity: 1,
        } as Konva.TextConfig;
    }
}
