
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import { User } from '@/modules/auth/shared/models/user';
import { httpClient } from '@/shared/services';
import _ from 'lodash';

@Component({})
export default class ObservableWatch extends Vue {
    @PropSync('observers', { type: Array }) public observersSync!: User[];
    @Prop(String) public customApiRoute!: string;
    @Prop({ type: String, default: 'primary' }) public activeColor!: string;
    @Prop(String) public type!: string;
    @Prop([String, Number]) public id!: string | number;
    @Prop(Boolean) public forDeletedElement!: boolean;

    public menu = false;
    public followingLoading = false;

    public confirmDialog = false;

    get loading() {
        return this.$store.state.adminState.loading;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get currentUserId() {
        if (this.$store.state.authState.user) {
            return this.$store.state.authState.user.id;
        } else {
            return 0;
        }
    }

    get isCurrentUserFollowing() {
        return this.observersSync.findIndex((user: User) => user.id === this.currentUser.id) !== -1;
    }

    get route() {
        let { type, id } = this;
        if (!type) {
            type = this.$route.fullPath.split('/').at(-2) as string;
            type = type.slice(0, type.length - 1);
        }
        if (!id) {
            id = this.$route.fullPath.split('/').at(-1) as string | number;
        }

        return `api/v1/observers/${type}/${id}/${this.isCurrentUserFollowing ? 'unfollow' : 'follow'}`;
    }

    public toggleWatch() {
        this.followingLoading = true;
        httpClient
            .post(this.route)
            .then((res) => {
                if (res.status === 204) {
                    if (this.isCurrentUserFollowing) {
                        this.removeCurrentUser();
                    } else {
                        this.addCurrentUser();
                    }
                } else {
                    throw new Error();
                }
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            })
            .finally(() => {
                this.followingLoading = false;
            });
    }

    private removeCurrentUser() {
        this.observersSync = this.observersSync.filter((user: User) => user.id !== this.currentUserId);
        this.$forceUpdate();
        this.$emit('unfollow');
    }

    private addCurrentUser() {
        this.observersSync.unshift(this.currentUser);
    }
}
