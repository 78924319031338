import {TicketFormGetters} from '@/modules/tickets/components/form/getters';
import {actionsTypes as userActions} from '@/modules/admin/shared/state';
import {TicketPriority, TicketStatus, TicketTagDataRequest} from '@/modules/tickets/shared';
import { logger } from '@/shared/services';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';

export class TicketFormMethods extends TicketFormGetters {
    public fetchPriorities() {
        this.$store.dispatch(this.prioritiesModule.actionsTypes.FETCH_DATA, {simple: false})
            .then(({data}: ModuleFetchPayload<TicketPriority>) => {
                if (data) {
                    const defaultPriority = data.find((priority) => priority.default);
                    if (defaultPriority && !this.itemData.ticket_priority_id) {
                        this.$set(this.itemData, 'ticket_priority_id', defaultPriority.id);
                    }
                }
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    public fetchStatuses() {
        this.$store.dispatch(this.statusesModule.actionsTypes.FETCH_DATA, {simple: false})
            .then(({data}: ModuleFetchPayload<TicketPriority>) => {
                if (data) {
                    const defaultStatus = data.find((status) => status.default);
                    if (defaultStatus && !this.itemData.ticket_status_id) {
                        this.$set(this.itemData, 'ticket_status_id', defaultStatus.id);
                    }
                }
            })
            .catch((e) => {
                logger.error(e);
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    public onDueDateCleared() {
        this.$set(this.itemData, 'due_date', null);
    }

    public created() {
        this.$store.dispatch(this.tagsModule.actionsTypes.FETCH_DATA, {simple: false})
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
        this.$store.dispatch(userActions.FETCH_USER_DATA, {simple: true})
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    // HELPERS

    public isString(value: any) {
        return typeof value === 'string';
    }
}
