export const viewTypes = [
    {id: 'dayGridMonth', name: 'Miesiąc'},
    {id: 'timeGridWeek', name: 'Tydzień'},
    {id: 'timeGridDay', name: 'Dzień'},
    {id: 'listWeek', name: 'Lista'},
];

export const eventAssignables = [
    {
        type: 'investment',
        route: 'investment-details',
        name: 'inwestycją',
        color: '#147BD1',
    },
    {
        type: 'investment-object',
        color: '#F7EA48',
        name: 'kategorią inwestycji',
    },
    {
        type: 'investment-subject',
        route: 'subject-details',
        color: '#2DC84D',
        name: 'przedmiotem inwestycji',
    },
    {
        type: 'acceptance',
        route: 'acceptance-details',
        color: '#E03C31',
        name: 'odbiorem',
    },
    {
        type: 'acceptance-note',
        color: '#753BBD',
        name: 'uwagą odbioru',
    },
    {
        type: 'ticket',
        route: 'ticket-details',
        color: '#B7CC33',
        name: 'zadaniem',
    },
    {
        type: 'sale',
        route: 'sale-details',
        color: '#FF00A5',
        name: 'leadem sprzedażowym',
    },
];
