
import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import Konva from 'konva';
import {KonvaEventObject} from 'konva/lib/Node';
import {StageConfig} from 'konva/lib/Stage';
import Vector2d = Konva.Vector2d;
import TextConfig = Konva.TextConfig;
import {findLastId} from '@/modules/investments/components/generator/helpers';
import {AcceptanceNoteDataRequest} from '@/modules/acceptances/shared/requests/acceptance-note-data-request';
import AcceptanceList from '@/modules/acceptances/shared/models/acceptance-list';
import {AcceptanceNote} from '@/modules/acceptances/shared/models/acceptance-note';
import {ModuleState} from '@/shared/state/template/module-state';
import NoteDetails from '@/modules/acceptances/components/notes/details.vue';
import {acceptanceNotesModule} from '@/modules/acceptances/shared/state/submodules';
import {mdiMapMarker, mdiMarker, mdiNote} from '@mdi/js';
import {IFrame} from 'konva/lib/types';

@Component({
    components: {
        NoteDetails,
    },
    props: {
        itemData: Object,
        list: Object,
        edit: Boolean,
    },
})
export default class AcceptancePlan extends Vue {
    public canvasWidth = window.innerWidth;
    public canvasHeight = window.innerHeight;
    public canvasScale = 1;
    public backgroundLoading = false;
    public scaleBy = 0.79;
    public itemData!: AcceptanceNoteDataRequest;
    public list!: AcceptanceList;
    public store: ModuleState<AcceptanceNote, AcceptanceNoteDataRequest>
        = this.$store.state.acceptancesState.acceptanceNotesState;
    public mutationTypes = acceptanceNotesModule.mutationsTypes;

    public background: HTMLImageElement = new Image();
    public setNote = false;
    public tooltipX = 0;
    public tooltipY = 0;
    public edit!: boolean;
    public lastCenter: { x: number, y: number } | null = null;
    public lastDistance: number | null = null;
    public noteDialog = false;
    public selectedList = null;

    public getCenter(p1: { x: number, y: number }, p2: { x: number, y: number }) {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    public getDistance(p1: { x: number, y: number }, p2: { x: number, y: number }) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    get lists() {
        return this.$store.state.acceptancesState.acceptanceListState.data;
    }

    get acceptance() {
        return this.$store.state.acceptancesState.current;
    }

    get editedPoint() {
        return this.itemData;
    }

    set editedPoint(value) {
        this.$emit('update:item-data', value);
    }

    get selectedNote() {
        return this.store.current;
    }

    set selectedNote(value) {
        this.$store.commit(this.mutationTypes.UPDATE_CURRENT, value);
    }

    get stageConfig() {
        return {
            width: this.canvasWidth,
            height: this.canvasHeight,
            draggable: true,
            scale: {x: this.canvasScale, y: this.canvasScale},
        } as StageConfig;
    }

    public selectPoint(e: KonvaEventObject<TouchEvent>) {
        if (this.edit && this.stage && this.stage.pointerPos) {
            if (!e.evt.touches || (e.evt.touches && e.evt.touches.length < 2)) {
                const x = ((this.stage.pointerPos.x - this.stage.x()) / this.canvasScale);
                const y = ((this.stage.pointerPos.y - this.stage.y()) / this.canvasScale);
                this.$set(this.editedPoint, 'x', this._.round(x));
                this.$set(this.editedPoint, 'y', this._.round(y));
            }
        }
    }

    get stage(): Konva.Stage {
        const stageComponent: any = this.$refs.stage;
        return stageComponent ? stageComponent.getStage() : null;
    }

    public zoom(e: KonvaEventObject<WheelEvent>, zoom: boolean = true) {
        e.evt.preventDefault();
        const pointer = this.stage.getPointerPosition();
        if (
            pointer &&
            (e.evt.deltaY > 0 || this.canvasScale <= (this.background.width / this.canvasWidth) * 5) &&
            (e.evt.deltaY < 0 || this.canvasScale >= (this.background.width / this.canvasWidth) / 2)
        ) {
            const oldScale = Number(this.canvasScale);

            const mousePointTo = {
                x: (pointer.x - this.stage.x()) / oldScale,
                y: (pointer.y - this.stage.y()) / oldScale,
            };

            if (zoom) {
                this.canvasScale = e.evt.deltaY > 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
            }

            const newPos = {
                x: pointer.x - mousePointTo.x * this.canvasScale,
                y: pointer.y - mousePointTo.y * this.canvasScale,
            };
            this.stage.position(newPos);
            this.stage.batchDraw();
        }
    }

    public moveStage(e: KonvaEventObject<MouseEvent | TouchEvent>) {
        if (e.evt && (e.evt instanceof TouchEvent)) {
            switch (e.evt.touches.length) {
                case 1:
                    if (!e.target.className) {
                        this.stage.stopDrag();
                    }
                    break;
                case 2:
                    this.stage.startDrag();
                    break;
            }
        }
    }

    public mounted() {
        Konva.hitOnDragEnabled = true;
        this.changeBackground();
        window.addEventListener('resize', () => {
            this.canvasWidth = window.innerWidth;
            this.canvasHeight = window.innerHeight;
        });
    }

    public changeBackground() {
        const background = new Image();
        background.src = this.acceptance.reference_photo ? this.acceptance.reference_photo.url : '/assets/photo.png';

        this.backgroundLoading = true;
        background.onload = () => {
            this.backgroundLoading = false;
            let newPos;
            if (this.canvasWidth > this.canvasHeight || this.background.width < this.background.height) {
                newPos = {
                    x: (this.canvasWidth / 2 - background.width / 2) * this.canvasScale,
                    y: 0,
                };
            } else {
                newPos = {
                    x: 0,
                    y: 0,
                };
            }
            this.stage.position(newPos);
            this.canvasScale = this.canvasHeight / background.height;
            this.background = background;
        };
    }

    public touchZoom(e: KonvaEventObject<TouchEvent>) {
        e.evt.preventDefault();
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
            // if the stage was under Konva's drag&drop
            // we need to stop it, and implement our own pan logic with two pointers
            if (this.stage.isDragging()) {
                this.stage.stopDrag();
            }

            const p1 = {
                x: touch1.clientX,
                y: touch1.clientY,
            };
            const p2 = {
                x: touch2.clientX,
                y: touch2.clientY,
            };

            if (!this.lastCenter) {
                this.lastCenter = this.getCenter(p1, p2);
                return;
            }
            const newCenter = this.getCenter(p1, p2);

            const dist = this.getDistance(p1, p2);

            if (!this.lastDistance) {
                this.lastDistance = dist;
            }

            const pointTo = {
                x: (newCenter.x - this.stage.x()) / this.canvasScale,
                y: (newCenter.y - this.stage.y()) / this.canvasScale,
            };

            this.canvasScale = this.canvasScale * (dist / this.lastDistance);


            // calculate new position of the stage
            const dx = newCenter.x - this.lastCenter.x;
            const dy = newCenter.y - this.lastCenter.y;

            const newPos = {
                x: newCenter.x - pointTo.x * this.canvasScale + dx,
                y: newCenter.y - pointTo.y * this.canvasScale + dy,
            };

            this.stage.position(newPos);

            this.lastDistance = dist;
            this.lastCenter = newCenter;
        }
    }

    get newPoint() {
        return this.planMarker(this.editedPoint.x || 0, this.editedPoint.y || 0);
    }

    public showNote(index: number) {
        this.selectedNote = this.store.data[index];
        this.noteDialog = true;
    }

    get notes(): Konva.PathConfig[] {
        return !this.backgroundLoading && this.store.data
            ? this.store.data
                .filter((e: AcceptanceNote) => !this.selectedList || e.acceptance_list_id === this.selectedList)
                .map(({x, y, is_done}: AcceptanceNote) => this.planMarker(x, y, is_done))
            : [];
    }

    public planMarker(x: number, y: number, closed?: boolean) {
        return {
            x,
            y,
            offsetX: 12,
            offsetY: 22,
            data: !this.setNote ? mdiMapMarker : mdiNote,
            fill: !this.setNote ? 'red' : '#FFD52E',
            strokeWidth: 0.2,
            stroke: '#ccc',
            shadowBlur: 2,
            opacity: !closed ? 1 : 0.5,
            shadowOffset: {x: 1.25, y: 1.25},
            shadowOpacity: 0.5,
            scale: {
                x: ((this.background.height / this.canvasHeight) / this.canvasScale) * 2.25,
                y: ((this.background.height / this.canvasHeight) / this.canvasScale) * 2.25,
            }
        } as Konva.PathConfig;
    }
}
