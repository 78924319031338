
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ToolbarItem extends Vue {

    @Prop({
        type: String,
        required: true,
    })
    public icon!: string;
    @Prop({
        type: String,
        required: true,
    })
    public title!: string;
    @Prop({
        type: Function,
        required: true,
    })
    public action!: string;
    @Prop({
        type: Function,
        default: () => true,
    })
    public isActive!: () => boolean;
    @Prop(Boolean) public readOnly!: boolean;

}
