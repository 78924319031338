
    import {Component, Vue, Watch} from 'vue-property-decorator';

    @Component({
        props: {
            label: String,
            outlined: Boolean,
            initialTime: String,
            errorMessages: Array,
            disabled: Boolean,
            rules: Array,
        },
    })
    export default class TimePicker extends Vue {
        private label!: string;
        private initialTime!: string;
        private dateMenu: boolean = false;
        private date: string = '';
        private menu: any = this.$refs.dateMenu as any;

        @Watch('date')
        public OnDateChange() {
            this.$emit('timePicked', this.date);
        }

        @Watch('initialTime')
        public OnInitDateChange() {
            this.date = this.initialTime;
            this.saveDate(this.initialTime);
        }

        public created() {
            this.date = this.initialTime;
            this.saveDate(this.initialTime);
        }

        public saveDate(date?: string) {
            const menu = this.$refs.dateMenu as any;

            if (menu) {
                menu.save(date || this.date);
            }
        }
    }
