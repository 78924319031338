
import Vue from 'vue';
import {acceptanceNotesModule} from '@/modules/acceptances/shared/state/submodules';
import {Component} from 'vue-property-decorator';
import Ticket from '@/modules/tickets/components/kanban/ticket.vue';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import mime from 'mime';
import SelectMediaList from '@/modules/media/components/select.vue';
import {ModuleState} from '@/modules/auth/shared/state';
import {InvestmentSubjectMediaFile} from '@/modules/investments/shared/models/subjects/investment-subject-media-file';
import {InvestmentSubjectMediaFileDataRequest} from '@/modules/investments/shared/requests/subjects/investment-subject-media-file-data-request';
import {investmentSubjectMediaFilesModule} from '@/modules/investments/shared/state/submodules';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import {parseDate, parseDateTime} from '@/shared/helpers';

@Component({
    components: {
        Ticket,
        SelectMediaList,
        NameReduce,
    },
    props: {
        id: Number,
        acceptance_list: Object,
        acceptance_list_id: Number,
        acceptance_point: Object,
        acceptance_point_id: Number,
        attachments: Array,
        description: String,
        is_done: Boolean,
        name: String,
        owner: Object,
        owner_id: Number,
        ticket: Object,
        ticket_id: Number,
        x: Number,
        y: Number,
    },
})
export default class NoteCard extends Vue {

    public files: MediaFile[] = [];
    public parseDate = parseDate;

    public isMobile = window.innerWidth < 1264;

    public onResize() {
        this.isMobile = window.innerWidth < 1264;
    }

    public playerOptions(item: MediaFile) {
        return {
            sources: [
                {
                    type: mime.getType(item.extension),
                    src: item.video_url,
                },
            ],
            autoplay: 'play',
            muted: true,
            fluid: true,
            poster: item.thumbnail ? item.thumbnail : '/assets/photo.png',
        };
    }
}
