

import {Component} from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import {investmentSubjectRoomsModule} from '@/modules/investments/shared/state/submodules';
import {ModuleProps} from '@/shared/state/template/module-props';
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';

@Component
export default class InvestmentSubjectRoomDetails extends DetailsComponent<InvestmentSubjectRoom> {
        public props: ModuleProps = investmentSubjectRoomsModule.moduleProps;
        public actionsTypes = investmentSubjectRoomsModule.actionsTypes;
        public showAction = this.actionsTypes.SHOW_ITEM;
        public store = this.$store.state.investmentsState.subjectRoomsState;

        public created() {
            // this.fetchDetails();
        }
}

