export class AcceptanceDataRequest {
    public id: number = 0;
    public name: string = '';
    // tslint:disable-next-line:variable-name
    public acceptance_template_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_subject_id: number = 0;
    // tslint:disable-next-line:variable-name
    public reference_photo_id: number = 0;
    // tslint:disable-next-line:variable-name
    public assigned_user_id: number = 0;
    // tslint:disable-next-line:variable-name
    public created_at = new Date();
    // tslint:disable-next-line:variable-name
    public updated_at = new Date();

    public rooms: any[] = [];
}
