
import {Component, Prop, Watch} from 'vue-property-decorator';
import {ErrorBoundary} from '@/shared/components';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import MediaDetails from '@/modules/media/components/details.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import {callSuper, numeralVariations} from '@/shared/helpers';
import {mediaListHeaders} from '@/modules/media/shared/config';
import {MediaMetaItem} from '@/modules/media/shared/models/media-file';
import MediaFileUploadForm from '@/modules/media/components/form.vue';
import {fileNumerals} from '@/shared/config/media';
import MediaCarousel from '@/modules/media/components/carousel.vue';
import {bytesToSize, getIcon} from '@/modules/media/shared/helpers';
import {ModuleProps} from '@/shared/state/template/module-props';
import {mediaProps} from '@/modules/media/shared/state/state';

// @ts-ignore-next-line
import VueFullScreenFileDrop from 'vue-full-screen-file-drop';

import 'vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css';
import { investmentSubjectPlansModule } from '../../shared/state/submodules';
import { InvestmentSubjectPlan } from '../../shared/models/investment-subject-plan';
import { InvestmentSubjectPlanSelect } from '../../shared/models/investment-subject-plan-select';

import { InvestmentSubjectPlanDataRequest } from '../../shared/requests/investment-subject-plan-data-request';

@Component({
    props: {
        value: Array,
        filters: Object,
        returnObject: Boolean,
        selectSingle: Boolean,
        mediaFileProperty: String,
        itemDisabledTooltip: String,
        preSavedFunction: Function,
    },
    components: {
        ErrorBoundary,
        ActionConfirmDialog,
        MediaFileUploadForm,
        VueFullScreenFileDrop,
        MediaCarousel,
        MediaDetails,
        ListToolbar,
    },
})
export default class SelectPlanList extends ListComponent
    <InvestmentSubjectPlanSelect, InvestmentSubjectPlanDataRequest> {

    @Prop(Function) public itemDisabled!: (item: InvestmentSubjectPlan) => {};
    @Prop(Number) public subjectId!: number;

    public headers: object[] = mediaListHeaders;
    public mutationTypes = investmentSubjectPlansModule.mutationsTypes;

    public actionsTypes = investmentSubjectPlansModule.actionsTypes;
    public store = this.$store.state.investmentsState.subjectPlansState;

    public fileNumerals = fileNumerals;
    public selectSingle!: boolean;
    public numeralVariations = numeralVariations;
    public getIcon = getIcon;
    public bytesToSize = bytesToSize;
    public props: ModuleProps = mediaProps;
    public selectedItems: InvestmentSubjectPlanSelect[] = [];
    public value!: InvestmentSubjectPlan[];
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_MEDIA;
    public filters!: object;
    public currentItem: number = 0;
    public itemsCount: number[] = [6];

    get items() {
        if (!this.$props.mediaFileProperty) {
            return this.store.data;
        } else {
            return this.store.data.map((el: any) => {
                return el[this.$props.mediaFileProperty];
            });
        }
    }

    public createItem(item?: InvestmentSubjectPlan) {
        this.setDefaultData(item || ({} as InvestmentSubjectPlan));
        this.store.formDialog = true;
    }

    get hasChanged() {
        return !this._.isEqual(
            this.selectedIds,
            this.value.map((item) => item.id),
        );
    }

    public created() {
        this.id = this.subjectId;
        if (this.value) {
            this.selected = JSON.parse(JSON.stringify(this.value));
        }
        if (this.selected.length > 0) {
            this.page = this.selected[0].page;
        } else {
            this.page = 1;

        }

        this.fetchParams = {
            select: 1,
            ...this.fetchParams,
        };
        if (this.filters) {
            this.fetchParams.filters = this.filters;
        }

        this.fetchData();

    }

    public preItemSaved(e: any) {
        this.$props.preSavedFunction(this.items.concat(e));
        this.itemSaved();
    }

    public mounted() {
        this.selectedItems = JSON.parse(JSON.stringify(this.value));
        if (this.selectedItems.length > 0) {
            this.page = this.selectedItems[0].page;
        }
    }

    get footerProps() {
        this.footer.disableItemsPerPage = true;
        this.footer.itemsPerPageOptions = [6];
        this.footer.disablePagination = false;
        return this.footer;
    }

    public isSelected(id: number) {
        return id === this.selectedIds[this.currentItem];
    }

    get selectedIds(): number[] {
        return this.selectedItems.length > 0 ? this.selectedItems.map((e: any) => e.id) : [];
    }

    public isInSelected(id: number) {
        return this.selectedIds.includes(id);
    }

    @Watch('currentItem')
    public changedCurrentItem(newVal: number) {
        if (this.selectedItems[newVal] && this.selectedItems[newVal].page !== this.page) {
            this.page = this.selectedItems[newVal].page;
        }
    }

    public addItem(item: InvestmentSubjectPlanSelect) {
        if (!this.selectedIds.includes(item.id)) {
            item.page = this.page;
            if (!(this.selectSingle && this.selectedItems.length < 2)) {
                this.selectedItems.push(item);
            } else {
                this.selectedItems = [item];
            }
            this.currentItem = this.selectedItems.length - 1;
        } else {
            if (this.isSelected(item.id)) {
                this.selectedItems.splice(this.selectedIds.indexOf(item.id), 1);
            } else {
                this.currentItem = this.selectedIds.indexOf(item.id);
            }
        }
    }

    get files() {
        return this.store.data;
    }

    public removeItems() {
        this.select = true;
        this.store.removeDialog = true;
    }

    @Watch('value')
    public onValueChanged(newVal: InvestmentSubjectPlanSelect[]) {
        this.selectedItems = JSON.parse(JSON.stringify(this.value));
    }

    get removeText() {
        return (
            'Czy na pewno usunąć ' +
            (this.select ? numeralVariations(this.selectedItems.length, fileNumerals) : 'ten plik') +
            '?'
        );
    }

    get bytesToGB() {
        return this.meta && this.meta.used_media_size_bytes ? this.meta.used_media_size_bytes * Math.pow(10, -9) : 0;
    }

    get meta() {
        return this.store.meta as MediaMetaItem;
    }

    get usedStorageBar() {
        return this.meta ? (this.meta.used_media_size_bytes / this.meta.max_media_size_bytes) * 100 : 0;
    }

    public onDrop(formData: FormData, files: FileList) {
        if (this.store.formDialog) {
            this.store.files = [...this.store.files, ...Array.from(files)];
        } else {
            this.store.files = Array.from(files);
        }
        this.store.formDialog = true;
    }
}
