var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"bottom":"","right":"","transition":"scale-transition","close-on-content-click":false,"origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({style:({'pointer-events': _vm.readOnly ? 'none' : 'auto'}),attrs:{"small":_vm.small,"x-small":_vm.xSmall,"outlined":_vm.outlined,"disabled":_vm.disabled},on:{"click":_vm.fetchUsers}},!_vm.usersLoading ? on : {}),[(_vm.activeUser)?_c('div',[(_vm.activeUser && _vm.activeUser.avatar_photo)?_c('v-avatar',{staticClass:"mr-2"},[_c('v-img',{attrs:{"lazy-src":"/assets/avatar.png","src":_vm.activeUser && _vm.activeUser.avatar_photo
                                ? _vm.activeUser.avatar_photo
                                : '/assets/avatar.png'}})],1):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.activeUser.name)+" ")]),(_vm.currentUser && _vm.currentUser.id === _vm.activeUser.id)?_c('b',{staticClass:"ml-1"},[_vm._v(" (Ja) ")]):_vm._e()],1):_vm._e()])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"width":"300"}},[_c('v-list',[_c('v-text-field',{staticClass:"mr-2 ml-2",attrs:{"no-details":"","label":"Szukaj użytkownika","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mb-2"}),(!_vm.search && !_vm.usersLoading)?_c('v-list-item',{attrs:{"disabled":!_vm.chosenOption && !_vm.user},on:{"click":() => {
                        _vm.chosenOption = null;
                        _vm.changeUser();
                    }}},[_c('div',[_c('span',[_vm._v(" Nieprzypisane ")]),_c('br'),(!_vm.chosenOption && !_vm.user)?_c('sup',[_vm._v("Aktualnie wybrane")]):_vm._e()])]):_vm._e(),(_vm.currentUser && !_vm.search && !_vm.usersLoading)?_c('v-list-item',{attrs:{"disabled":_vm.activeUser.id === _vm.currentUser.id},on:{"click":() => {
                        _vm.chosenOption = _vm.currentUser;
                        _vm.changeUser();
                    }}},[_c('div',[_c('span',[_vm._v(" Przypisz do mnie ")]),_c('br'),(_vm.activeUser.id === _vm.currentUser.id)?_c('sup',[_vm._v("Aktualnie wybrane")]):_vm._e()])]):_vm._e(),_vm._l((_vm.users),function(option){return _c('v-list-item',{key:option.id,attrs:{"disabled":_vm.activeUser.id === option.id},on:{"click":() => {
                        _vm.chosenOption = option;
                        _vm.changeUser();
                    }}},[_c('div',{staticClass:"d-flex justify-center align-center"},[(option && option.avatar_photo)?_c('v-avatar',{staticClass:"ma-2 ml-0",class:{ 'mt-1': option.id === _vm.activeUser.id },attrs:{"size":"18"}},[_c('v-img',{attrs:{"lazy-src":"/assets/avatar.png","src":option && option.avatar_photo ? option.avatar_photo : '/assets/avatar.png'}})],1):_vm._e(),_c('div',[_c('span',[_vm._v(" "+_vm._s(option.name)+" "),(_vm.currentUser && _vm.currentUser.id === option.id)?_c('b',{staticClass:"ml-1"},[_vm._v(" (Ja) ")]):_vm._e()]),_c('br'),(_vm.activeUser && _vm.activeUser.id === option.id)?_c('sup',[_vm._v("Aktualnie przypisany")]):_vm._e()])],1)])}),(_vm.users.length < 1 && _vm.search && !_vm.usersLoading)?_c('v-list-item',{staticClass:"grey--text text-center",attrs:{"disabled":""}},[_c('v-list-item-subtitle',[_vm._v(" Nie znaleziono użytkownika")])],1):_vm._e(),(_vm.usersLoading)?_c('v-list-item',{staticClass:"grey--text text-center",attrs:{"disabled":""}},[_c('v-list-item-subtitle',[_vm._v(" Wczytywanie")])],1):_vm._e(),(_vm.meta && _vm.meta.last_page > 1)?_c('v-pagination',{attrs:{"length":_vm.meta.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }