
    import { Component, Vue } from 'vue-property-decorator';
    import { contrast, hexToRgb } from '@/shared/helpers/colors';

    @Component({
        props: {
            color: String,
        },
    })
    export default class TextContrast extends Vue {
        public whiteColor = hexToRgb('#ffffff');
        public blackColor = hexToRgb('#000000');
        public color!: string;

        get isDark() {
            return contrast(hexToRgb(this.color), this.whiteColor) > 2;
        }

        get isLight() {
            return contrast(hexToRgb(this.color), this.blackColor) > 2;
        }
    }
