
import ListComponent from '@/shared/components/layout/list/list-component';
import { AcceptanceNoteDataRequest } from '../../shared/requests/acceptance-note-data-request';
import { AcceptanceNote } from '@/modules/acceptances/shared/models/acceptance-note';
import { acceptanceNotesModule } from '@/modules/acceptances/shared/state/submodules';
import { ModuleProps } from '@/shared/state/template/module-props';
import { acceptanceNotesListHeaders } from '@/modules/acceptances/shared/config';
import { Component } from 'vue-property-decorator';
import NoteDetails from '@/modules/acceptances/components/notes/details.vue';
import AcceptanceList from '../../shared/models/acceptance-list';
import { AcceptancePointCategory } from '../../shared/models/acceptance-point-category';

@Component({
    props: {
        id: Number,
    },
    components: {
        NoteDetails,
    },
})
export default class AcceptanceNotesList extends ListComponent<AcceptanceNote, AcceptanceNoteDataRequest> {
    public actionsTypes = acceptanceNotesModule.actionsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers: object[] = acceptanceNotesListHeaders;
    public mutationTypes = acceptanceNotesModule.mutationsTypes;
    public props: ModuleProps = acceptanceNotesModule.moduleProps;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.acceptancesState.acceptanceNotesState;

    // get items(){
    //     this.store.state.acceptancesState.current
    //     this.store.current
    // }

    get acceptance() {
        return this.$store.state.acceptancesState.current;
    }

    get lists() {
        return this.$store.state.acceptancesState.acceptanceListState.data;
    }

    public getCategories(list: AcceptanceList) {
        return [
            ...list.point_categories.filter(
                (category: AcceptancePointCategory) =>
                    this.allNotesLength(this.getListOfNotesInCategory(list, category)) >
                    this.getListOfNotesInCategory(list, category)[1].length,
            ),
            ...list.point_categories.filter(
                (category: AcceptancePointCategory) =>
                    !(
                        this.allNotesLength(this.getListOfNotesInCategory(list, category)) >
                        this.getListOfNotesInCategory(list, category)[1].length
                    ),
            ),
        ];
    }

    public mounted() {
        if (this.store.data.length <= 0) {
            this.fetchData();
        }
    }

    public getListOfNotesInCategory(list: AcceptanceList, category: AcceptancePointCategory) {
        const listOfNotes = this.items.filter(
            (note) =>
                note.acceptance_point.acceptance_point_category_id === category.id &&
                note.acceptance_list_id === list.id,
        );
        return [
            listOfNotes.filter((val) => !this.isNoteTicketDone(val)),
            listOfNotes.filter((val) => this.isNoteTicketDone(val)),
        ];
    }

    public isNoteTicketDone(note: AcceptanceNote) {
        return !note.is_done;
    }

    public allNotesLength(lists: any[]) {
        return lists[0].length + lists[1].length;
    }

    public isCategoryDisabled(list: AcceptanceList, category: AcceptancePointCategory) {
        return (
            this.items.filter(
                (note) =>
                    note.acceptance_point.acceptance_point_category_id === category.id &&
                    note.acceptance_list_id === list.id,
            ).length <= 0
        );
    }
}
