export class AcceptanceTemplatePointDataRequest {
    public id: number = 0;

    // tslint:disable-next-line:variable-name
    public acceptance_point_category_id: number = 0;
    public name: string = '';
    public description: string = '';
}
// export class AcceptanceTemplatePointDataRequest {
//     public id: number = 0;
//     public itemPropertyIdName: id = 0;
//     // tslint:disable-next-line:variable-name
//     public company_name: string = '';
//     public nip: string = '';
//     public name: string = '';
//     public email: string = '';
//     public phone: string = '';
//     public address: string = '';
//     public zip: string = '';
//     public city: string = '';
//     public region: string = '';
//     public country: string = '';
//     public description: string = '';
// }
