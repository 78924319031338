
import { ModuleState } from '@/shared/state/template/module-state';
import _ from 'lodash';
import { isString } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, VModel, Watch } from 'vue-property-decorator';
import ActionConfirmDialog from '../dialogs/action-confirm-dialog.vue';
import ChipColor from './chip-color.vue';
import TagForm from '@/shared/components/elements/tag-form.vue';

@Component({
    components: {
        ChipColor,
        ActionConfirmDialog,
        TagForm,
    },
})
export default class TagsInput extends Vue {
    @VModel() public inputValue!: Array<{ [k: string]: string }>;
    @Prop(Object) public store!: ModuleState<any, any>;
    @Prop(Object) public actions!: { [k: string]: string };
    @Prop(Object) public mutations!: { [k: string]: string };
    @Ref() public readonly inputElement!: Vue;

    public search = '';
    public tagsSearch: any = null;
    public newTagDialog = false;
    public isString = isString;

    get tags() {
        return this.store.data;
    }

    get tagLoading() {
        return this.store.loadingItem || this.store.loading;
    }

    get tagEdited() {
        return this.store.editedItem;
    }

    set tagEdited(value) {
        this.$store.commit(this.mutations.UPDATE_EDITED, value);
    }

    get tagsLoading() {
        return this.store.loading;
    }

    get searchAlreadyExists() {
        return !this.tagLoading && this.tags.some((el) => el.name === this.search);
    }

    public removeNewTag() {
        if (this.inputValue) {
            this.inputValue.pop();
        }
        this.newTagDialog = false;
    }

    public createTag(newTag: any) {
        if (!newTag || _.isEmpty(newTag)) {
            this.newTagDialog = false;
            return;
        }
        this.$store.dispatch(this.actions.STORE_ITEM, newTag).then((response) => {
            if (response.data) {
                const tag = response.data;

                if (typeof this.inputValue[this.inputValue.length - 1] === 'string') {
                    this.inputValue.pop();
                }

                this.inputValue.push(tag);
                this.search = '';
                this.newTagDialog = false;
            } else {
                this.removeNewTag();
            }
            this.$nextTick(() => {
                (this.inputElement.$refs!.input as HTMLInputElement).focus();
            });
        });
    }

    public addNewTag() {
        if (!this.search) {
            this.newTagDialog = true;
            return;
        }

        const exsistingTag = this.tags.find((tag) => tag.name === this.search.trim());

        if (exsistingTag) {
            this.inputValue.pop();
            this.inputValue.push(exsistingTag);
            return;
        }

        const enterEvent = new KeyboardEvent('keydown', {
            code: 'Tab',
            key: 'Tab',
            keyCode: 9,
            view: window,
        });

        (this.inputElement.$refs!.input as Element).dispatchEvent(enterEvent);
    }

    @Watch('search')
    public searchTags(search: string) {
        clearTimeout(this.tagsSearch);

        this.tagsSearch = setTimeout(() => {
            if (typeof search === 'string') {
                this.$store.dispatch(this.actions.FETCH_DATA, { filters: { search } });
            }
            this.tagsSearch = null;
        }, 300);
    }

    @Watch('inputValue')
    public onUserInput() {
        this.$nextTick(() => {
            const val = this.inputValue;
            if (!val) {
                return;
            }

            const el = val[val.length - 1];

            if (typeof el === 'string') {
                const exsistingTag = this.tags.find((tag) => tag.name === (el as string).trim());

                if (exsistingTag) {
                    this.inputValue.pop();
                    this.inputValue.push(exsistingTag);
                    return;
                }

                this.tagEdited = { name: el };
                this.newTagDialog = true;
                this.search = '';
                this.$forceUpdate();
                return;
            }

            this.$forceUpdate();
            this.$nextTick(() => {
                if (this.inputElement) {
                    (this.inputElement.$refs!.input as HTMLInputElement).focus();
                }
            });
        });
    }
}
