
import {Component} from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

import {TicketDataRequest, ticketsModule, TicketTagDataRequest} from '@/modules/tickets/shared';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import {callSuper} from '@/shared/helpers';
import {TicketFormMethods} from './form/methods';
import ChipColor from '@/shared/components/elements/chip-color.vue';
import CategoryForm from './settings/categories/form.vue';
import ObjectPicker from './pickers/object-picker.vue';
import TagsInput from '@/shared/components/elements/tags-input.vue';

@Component({
    components: {
        ChipColor,
        Wysiwyg,
        DatePicker,
        FormActions,
        AutoCompleteWithAddNew,
        ActionConfirmDialog,
        InvestmentTagChip,
        TextContrast,
        IconContrast,
        ObjectPicker,
        TagsInput,
    },
})
export default class TicketsForm extends TicketFormMethods {
    public actionsTypes = ticketsModule.actionsTypes;
    public store: any = this.$store.state.ticketsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new TicketDataRequest();
    public addingObject: boolean = false;

    // Tags related
    // public newTagDialog: boolean = false;
    // public tagsSearch: any = null;
    // public searchTag: string = '';

    // Components
    public CategoryForm = CategoryForm;

    get formTitle(): string {
        return this.edit ? 'Edytuj zadanie' : 'Dodaj zadanie';
    }

    public initForm() {
        this.form = this.$refs.createTicketForm;
        this.fetchPriorities();
        this.fetchStatuses();
        if (!this.itemData.tags) {
            this.$set(this.itemData, 'tags', []);
        }
        this.addingObject = this.itemData.assignable_id ? true : false;
    }

    public initFocus() {
        const defaultPriority = this.priorities.find((priority) => priority.default);
        if (defaultPriority && !this.itemData.ticket_priority_id) {
            this.$set(this.itemData, 'ticket_priority_id', defaultPriority.id);
        }
        const defaultStatus = this.statuses.find((status) => status.default);
        if (defaultStatus && !this.itemData.ticket_status_id) {
            this.$set(this.itemData, 'ticket_status_id', defaultStatus.id);
        }
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        await this.form.validate();

        if (this.itemData && this.valid && this.itemData.tags) {
            this.itemData.tags = this.itemData.tags.map((tag: any) => tag.id);
        }
        if (this.itemData && this.valid && this.itemData.assignable) {
            this.itemData.assignable_id = this.itemData.assignable.id;
            this.itemData.assignable_type = this.itemData.assignable.type;
        } else if (this.itemData && this.valid && this.itemData.assignable_id && !this.itemData.assignable) {
            this.itemData.assignable_id = null;
            this.itemData.assignable_type = null;
        }
        this.$emit('reload');
        return callSuper(this, 'submitForm', e, next);
    }

   public removeAssigned(val: boolean) {
        this.addingObject = !val;
        this.$set(this.itemData, 'assignable', null);
    }
}
