
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';
import {investmentSubjectRoomsModule} from '@/modules/investments/shared/state/submodules';
import {hexToRgb, contrast} from '@/shared/helpers/colors';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {AcceptanceTemplateList} from '../../shared/models/acceptance-template-list';
import {AcceptanceDataRequest} from '../../shared/requests/acceptance-data-request';
import {acceptanceTemplateListModule, acceptanceTemplatesModule} from '../../shared/state/submodules';
import SubFormActions from './sub-form-actions.vue';
import RoomDetails from '@/modules/investments/components/objects/subjects/rooms/details.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';

@Component({
    components: {
        SubFormActions,
        RoomDetails,
        NameReduce
    },
    props: {
        itemData: Object,
        subjectId: Number,
        templateId: Number,
        step: Number,
    },
})
export default class TemplateListAssigner extends Vue {
    get item() {
        return this.itemData;
    }

    set item(val) {
        this.$emit('update:itemData', val);
    }

    get valid() {
        if (!this.item || !this.item.rooms) {
            return false;
        }
        let val = true;
        this.item.rooms.forEach((element) => {
            if (!element.list_id || !element.room_id) {
                val = false;
            }
        });
        return val;
    }

    public isLoading = false;
    public subjectRoomsFetchAction = investmentSubjectRoomsModule.actionsTypes.FETCH_DATA;
    public roomsStore = this.$store.state.investmentsState.subjectRoomsState;
    public selectedSubjectRooms: InvestmentSubjectRoom[] = [];
    public itemData!: AcceptanceDataRequest;

    public templateListFetchAction = acceptanceTemplateListModule.actionsTypes.FETCH_DATA;
    // public templateListFetchAction = acceptanceTemplatesModule.actionsTypes.SHOW_ITEM;
    public selectedTemplateLists: AcceptanceTemplateList[] = [];
    public values: any[] = [];
    public showRoomInfo = false;

    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');

    public fetchData() {
        this.fetchLists(this.autoAssignListsToRooms);
        this.fetchRooms(this.autoAssignListsToRooms);
    }

    public fetchRooms(cb?: CallableFunction) {
        if (!this.$props.subjectId) {
            return;
        }
        this.$store.dispatch(this.subjectRoomsFetchAction, {id: this.$props.subjectId}).then(({data}) => {
            data.forEach((el: any) => {
                this.values.push({roomId: el.id, list: null});
            });

            this.selectedSubjectRooms = data;
            if (cb) {
                cb();
            }
        });
    }

    public fetchLists(cb?: CallableFunction) {
        if (!this.$props.templateId) {
            return;
        }
        this.$store
            .dispatch(this.templateListFetchAction, {
                filters: {acceptance_template_id: this.$props.templateId, without_empty: true, is_global: false},
                simple: true,
            })
            .then(({data}) => {
                // console.log(data);
                this.selectedTemplateLists = data;
                // this.selectedTemplateLists = data.lists;
                if (cb) {
                    cb();
                }
            });
    }

    @Watch('templateId')
    public onTemplateChange() {
        this.fetchLists(this.autoAssignListsToRooms);
    }

    @Watch('subjectId')
    public onSubjectChange() {
        this.fetchRooms(this.autoAssignListsToRooms);
    }

    public mounted() {
        this.fetchData();
    }

    public autoAssignListsToRooms() {
        if (this.selectedSubjectRooms.length > 0) {
            this.selectedSubjectRooms.forEach((item, index) => {
                const list = this.selectedTemplateLists.find(
                    (e) => e.room_type_id === item.investment_subject_room_type_id && !e.is_global
                );
                this.assignListToRoom(list, index);
            });
        }
    }

    public assignListToRoom(list: any, index: any) {
        this.values[index].list = list;
        const rooms = this.values.map((el) => {
            return {room_id: el.roomId, list_id: el.list ? el.list.id : null};
        });
        this.$set(this.item, 'rooms', rooms);
        this.$forceUpdate();
        // this.$props.itemData.lists = this.values;
        // this.$emit('update:itemData', { ...this.$props.itemData, rooms });
        // this.$set(this.$props.itemData, 'lists', this.values)
        // console.log(this.$props.itemData)
        // this.$forceUpdate();
    }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }
}
