export class CalendarEventDataRequest {
    public id?: number = 0;
    public name: string = '';
    // tslint:disable-next-line:variable-name
    public start_date: string = '';
    // tslint:disable-next-line:variable-name
    public end_date?: string = '';
    public color?: string = '';
    // tslint:disable-next-line:variable-name
    public assignable_id?: number = 0;
    // tslint:disable-next-line:variable-name
    public assignable_type?: string = '';
    public location?: string = '';
    // tslint:disable-next-line:variable-name
    public all_day: boolean = false;
    public description?: string = '';
    // tslint:disable-next-line:variable-name
    public event_time: number = 0;
    // tslint:disable-next-line:variable-name
    public initial_view?: string = '';
    // tslint:disable-next-line:variable-name
    public event_duration: string = '';
    // tslint:disable-next-line:variable-name
     public start_workday: string = '';
     // tslint:disable-next-line:variable-name
     public end_workday: string = '';
}
