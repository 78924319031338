
import {Component, Prop, Vue} from 'vue-property-decorator';
import {statusesModule, Ticket, ticketsModule, TicketStatus} from '@/modules/tickets/shared';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';

@Component({})
export default class StatusesPicker extends Vue {
  @Prop(Object) public status!: any;
  @Prop(Boolean) public small!: boolean;
  @Prop(Boolean) public outlined!: boolean;
  @Prop(Number) public ticketId!: number;
  @Prop(Object) public ticket!: any;
  @Prop(Boolean) public disabled!: boolean;
  @Prop(Boolean) public readOnly!: boolean;
  public menu = false;
  public beforeLoadedChip = {name: 'Brak', id: 1};
  public chosenOption: null | TicketStatus = null;
  public mutationTypes = ticketsModule.mutationsTypes;
  public actionTypes = ticketsModule.actionsTypes;
  public statusActions = statusesModule.actionsTypes;
  public store: any = this.$store.state.ticketsState;

  get statuses() {
    return this.store.statusesState.data;
  }

  get loading() {
    return this.store.statusesState.loading;
  }

  public fetchStatuses() {
    this.$store.dispatch(this.statusActions.FETCH_DATA, {simple: false})
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
        });
  }

  get user() {
    return this.$store.state.authState.user;
  }

  public changeStatus() {
    const id = this.$props.ticketId || this.$route.params.id;
    this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {
      id,
      ticket_status_id: this.activeStatus?.id,
    })
        .then(({data}: ModuleShowPayload<Ticket>) => {
          if (data) {
            this.$store.commit('SHOW_SNACKBAR', {type: 'success', text: 'Status zmieniony'});
          }
        });
  }

  get activeStatus() {
    return this.chosenOption
        ? this.chosenOption
        : this.$props.status
            ? this.$props.status
            : this.beforeLoadedChip;
  }

  public created() {
    if (this.statuses.length === 0) {
      this.fetchStatuses();
    }
  }
}
