
import {Component, Vue, Watch} from 'vue-property-decorator';
import SelectMediaList from '@/modules/media/components/select.vue';
import {MediaFile} from '@/modules/media/shared/models/media-file';

@Component({
    components: {
        SelectMediaList,
    },
    props: {
        label: String,
        disabled: Boolean,
        clearable: Boolean,
        filters: Object,
        icon: {
            type: String,
            default: 'mdi-file',
        },
        value: [Array, Number, Object],
        multiple: Boolean,
        error: String,
        errorMessages: [Object, Array],
        outlined: Boolean,
        customStore: Object,
        customFetchAction: String,
        mediaFileProperty: String,
        customCreatedItemProperties: Object,
        customMutations: Object,
        customStoreAction: String,
        preSavedFunction: Function,
    },
})
export default class MediaFileInput extends Vue {
    public mediaSelectDialog: boolean = false;
    public multiple!: boolean;
    public value!: any;
    public selectedItems = [];

    public mounted() {
        if (this.value) {
            this.selectedItems = !this.multiple ? [this.value] : this.value;
        }
    }

    get selected() {
        return this.selectedItems;
    }

    set selected(val) {
        this.selectedItems = val || [];
        this.$emit('input', this.multiple ? val : (val ? val[0] : null));
    }

    public itemSelected(e: any) {
        this.selected = e;
        this.mediaSelectDialog = false;
    }

    @Watch('value')
    public onValueChanged() {
        if (this.value) {
            this.selectedItems = !this.multiple ? [this.value] : this.value;
        } else {
            this.selectedItems = [];
        }
    }

    @Watch('selectedItems')
    public onSelectedChanged(val: MediaFile[]) {
        this.$emit('input', this.multiple ? val : (val ? val[0] : [null]));
    }
}
