
import {ticketsModule} from '@/modules/tickets/shared';
import Ticket from '@/modules/tickets/components/kanban/ticket.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import SubFormActions from '@/modules/acceptances/components/form/sub-form-actions.vue';
import {acceptancesModule} from '../../shared/state/module';
import {FormRules} from '@/shared/validation/form-rules';
import {AcceptanceNoteDataRequest} from '@/modules/acceptances/shared/requests/acceptance-note-data-request';
import {ModuleState} from '@/shared/state/template/module-state';
import SelectMedia from '@/modules/acceptances/components/form/select-media.vue';
import {AcceptanceNote} from '@/modules/acceptances/shared/models/acceptance-note';

@Component({
    components: {CardPicker, Ticket, NoteEntryDataForm, SubFormActions, SelectMedia},
    props: {itemData: Object, step: Number },
})
export default class NoteEntryDataForm extends Vue {
    public Ticket = Ticket;
    public ticketsStore = this.$store.state.ticketsState;
    public ticketsActionsTypes = ticketsModule.actionsTypes;
    public store: ModuleState<AcceptanceNote, AcceptanceNoteDataRequest> =
        this.$store.state.acceptancesState.acceptanceNotesState;
    public valid = false;

    public formRules = new FormRules();

    public acceptanceStore = this.$store.state.acceptancesState;
    public acceptancesActionTypes = acceptancesModule.actionsTypes;
    public selectedTicket = {};

    get errorMessages() {
        return this.store.errorMessages;
    }

    get subjectId() {
        return this.$store.state.acceptancesState.current.investment_subject_id;
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (Object.keys(this.errorMessages).length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }
}
