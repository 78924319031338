

import { User } from '@/modules/auth/shared/models/user';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        tooltipText: {
            type: String,
            default: '',
        },
        tooltipIcon: {
            type: String,
            default: 'mdi-help-circle-outline',
        },
        top: {
            type: Boolean,
            default: false,
        },
        bottom: {
            type: Boolean,
            default: true,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },

    }
})
export default class Hint extends Vue {

    public showTooltip: boolean = false;

    get user(): User {
        return this.$store.state.authState.user;
    }

}

