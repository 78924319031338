
import Vue from 'vue';
import Component from 'vue-class-component';
import SubFormActions from '@/modules/acceptances/components/form/sub-form-actions.vue';
import { investmentSubjectOperationsListFilters } from '@/modules/investments/shared/config';
import {
    investmentSubjectOperationsModule,
    investmentObjectsModule,
    investmentSubjectsModule,
} from '@/modules/investments/shared/state/submodules';
import { logger } from '@/shared/services';
import FilterSearch from '@/shared/components/layout/list/filter-bar/filter-search.vue';
import SelectTree from '@/shared/components/elements/select-tree.vue';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import SubjectCard from '@/modules/investments/components/subjects/subject-card.vue';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import { indexOf } from 'lodash';

@Component({
    components: {
        SubFormActions,
        FilterSearch,
        SelectTree,
        CardPicker,
        SubjectCard,
    },
    props: {
        step: Number,
        itemData: Object,
    },
})
export default class SelectSubject extends Vue {
    public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public subjectTypesActions = investmentSubjectsModule.actionsTypes;
    public objectsActions = investmentObjectsModule.actionsTypes;
    public objectFilterIndex = 0;
    public subjectsStore = this.$store.state.investmentsState.subjectOperationsState;
    public objectsFilterValue = this.objects;
    get valid() {
        return !!this.$props.itemData.investment_object_id && !!this.$props.itemData.investment_subject_id;
    }
    public filters = investmentSubjectOperationsListFilters;

    get objects() {
        return this.$store.state.investmentsState.objectsState.dataList.map((object: any) => {
            object = this.checkForSubjects(object);
            return object;
        });
    }

    get investments() {
        const invests: any[] = [];
        const obj: any[] = [];
        this.objects.forEach((object: any) => {
            if (invests.indexOf(object.investment_id) === -1) {
                const investobj = {
                    investment: object.investment,
                    id: object.investment_id,
                    disabled: object.subjects_count === 0 && !object.areitems ? true : false,
                };
                invests.push(object.investment_id);
                obj.push(investobj);
            } else {
              if (object.subjects_count !== 0 || this.objects.areitems) {
                obj[invests.indexOf(object.investment_id)].disabled = false;
              }
            }
        });
        return obj;
    }

    public checkForSubjects(objects: any) {
        if (objects.children.length !== 0) {
            objects.children.map((object: any) => {
                return this.checkForSubjects(object);
            });
            let items = 0;
            objects.children.forEach((child: any) => {
                if (child.areitems || child.subjects_count !== 0) {
                    items ++;
                }
            });
            if (items !== 0) {
                objects.areitems = true;
            }
        }
        if (objects.subjects_count === 0) {
            objects.disabled = true;
        }
        return objects;
    }

    public objectsFilter(obj: any) {
        this.objectsFilterValue = this.objects.filter((el: any) => el.investment === obj);
    }

    public mounted() {
        this.$store
            .dispatch(this.objectsActions.FETCH_DATA, {
                simple: true,
            })
            .catch((error) => {
                logger.error(error);
            });
    }
}
