
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { AcceptanceDataRequest } from '@/modules/acceptances/shared/requests/acceptance-data-request';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import { acceptanceTemplatesModule } from '@/modules/acceptances/shared/state/submodules';
import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { AcceptanceTemplate } from '@/modules/acceptances/shared/models/acceptance-template';
import TemplateListAssigner from '@/modules/acceptances/components/form/template-list-assigner.vue';
import EntryDataForm from './entry-data-form.vue';
import SelectSubject from '@/modules/acceptances/components/form/select-subject.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions, TemplateListAssigner, EntryDataForm, SelectSubject },
})
export default class AcceptanceForm extends FormComponent<AcceptanceDataRequest> {
    public actionsTypes = acceptancesModule.actionsTypes;
    public templateTypes = acceptanceTemplatesModule.actionsTypes;
    public subjectTypes = investmentSubjectOperationsModule.actionsTypes;

    public store = this.$store.state.acceptancesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new AcceptanceDataRequest();
    public createdAcceptanceId!: number;

    public currentStep = 1;
    public badRequest!: boolean;
    public isDone!: boolean;
    public goBack() {
        if (this.currentStep === 1) {
            this.$emit('canceled');
        } else {
            this.currentStep--;
        }
    }

    public getFormCompnent(step: number) {
        switch (step) {
            case 1:
                return 'SelectSubject';
            case 2:
                return 'EntryDataForm';
            case 3:
                return 'TemplateListAssigner';
        }
    }

    public created() {
        this.$store.dispatch(this.subjectTypes.FETCH_DATA, { simple: false });
        this.$store.dispatch(this.templateTypes.FETCH_DATA, { simple: false });
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj protokół odbioru' : 'Dodaj protokół odbioru';
    }

    get loadingTemplates() {
        return this.$store.state.acceptancesState.templatesState.loading;
    }

    get templates() {
        return this.$store.state.acceptancesState.templatesState.data;
    }

    get loadingSubjects() {
        return this.$store.state.investmentsState.subjectOperationsState.loading;
    }

    get subjects() {
        return this.$store.state.investmentsState.subjectOperationsState.data;
    }

    public changeName() {
        if (this.itemData.investment_subject_id && this.itemData.acceptance_template_id && !this.itemData.name) {
            const subject = this.subjects.find(
                (item: InvestmentSubject) => item.id === this.itemData.investment_subject_id,
            );
            const template = this.templates.find(
                (item: AcceptanceTemplate) => item.id === this.itemData.acceptance_template_id,
            );
            this.itemData.name = `${subject.name} - ${template.name}`;
        }
    }

    public initForm() {
        this.form = this.$refs.createAcceptanceForm;
        this.initFocus();
    }

    @Watch('currentStep')
    public onCurrentStepChange(val: number) {
        if (val === 4) {
            this.submitForm(null, null);
        }
    }

    public async submitForm(e: any, next: any) {
        this.$store
            .dispatch(this.storeAction, this.requestParams)
            .then(({ data }: any) => {
                this.createdAcceptanceId = data.id;
            })
            .catch((err: any) => {
                this.badRequest = !!err;
            })
            .finally(() => {
                this.isDone = true;
                this.$forceUpdate();
            });
    }

    public initFocus() {
        this.badRequest = false;
        this.isDone = false;
        this.currentStep = 1;
        this.requestData = new AcceptanceDataRequest();
    }
}
