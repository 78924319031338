import { AcceptancePointCategory } from '../models/acceptance-point-category';
import { AcceptanceTemplatePoint } from '../models/acceptance-template-point';

export class AcceptanceTemplateListDataRequest {
    public id: number = 0;
    public name: string = '';
    public color: string = '';
    // points: AcceptanceTemplatePoint[] = [];
    // point_categories: AcceptancePointCategory[] = [];
    // aceptance_point_categories_ids: number[] = [];

    // tslint:disable-next-line:variable-name
    public acceptance_template_id: number = 0;

    // tslint:disable-next-line:variable-name
    public room_type_id: number = 0;

    // tslint:disable-next-line:variable-name
    public is_global: boolean = false;

}
