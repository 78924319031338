
import { Component } from 'vue-property-decorator';
import { acceptanceTemplatesPointsListHeaders } from '@/modules/acceptances/shared/config';
import ListComponent from '@/shared/components/layout/list/list-component';
import { mdiHome } from '@mdi/js';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { acceptancesListHeaders } from '@/modules/acceptances/shared/config';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import AcceptancePointForm from '@/modules/acceptances/components/templates/points/form.vue';
import { AcceptancePoint } from '@/modules/acceptances/shared/models/acceptance-point';
import { AcceptanceTemplatePointDataRequest } from '@/modules/acceptances/shared/requests/acceptance-template-point-data-request';
import { acceptancePointsCategoriesModule, acceptanceTemplatePointsModule } from '@/modules/acceptances/shared/state/submodules';
import PointDetails from './details.vue';
import {templatePointsListFilter} from '@/modules/acceptances/shared/config/filters';

@Component({
    components: {
        AcceptancePointForm,
        ItemsTable,
        PointDetails,
    },
})
export default class AcceptancePointsList extends ListComponent<AcceptancePoint, AcceptanceTemplatePointDataRequest> {
    public headers: object[] = acceptanceTemplatesPointsListHeaders;
    public actionsTypes = acceptanceTemplatePointsModule.actionsTypes;
    public mutationTypes = acceptanceTemplatePointsModule.mutationsTypes;
    public props: ModuleProps = acceptanceTemplatePointsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.acceptancesState.templatePointsState;
    public filters = templatePointsListFilter;
    public categoriesActionsTypes = acceptancePointsCategoriesModule.actionsTypes;
    public showDialogFromUrl = false;
    public idDialogFromUrl: null | number = null;

    public created() {
        this.fetchData();
        this.$store.dispatch(this.categoriesActionsTypes.FETCH_DATA, {simple: false}).then((response: any) => {
            this.filters[0].data = response.data;
        });
    }
}
