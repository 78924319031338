
import { AcceptanceTemplateListDataRequest } from '@/modules/acceptances/shared/requests/acceptance-template-list-data-request';
import { acceptanceTemplateListModule } from '@/modules/acceptances/shared/state/submodules';
import FormComponent from '@/shared/components/layout/form/form-component';
import Component from 'vue-class-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { investmentSubjectRoomTypesModule } from '@/modules/investments/shared/state/submodules';
import ObjectsTypesSettingsForm from '@/modules/investments/components/settings/rooms/form.vue';
import { Inject } from 'vue-property-decorator';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
    },
})
export default class ListForm extends FormComponent<AcceptanceTemplateListDataRequest> {
    public store = this.$store.state.acceptancesState.acceptanceTemplateListState;
    public roomTypeForm = ObjectsTypesSettingsForm;
    public roomTypeModule = investmentSubjectRoomTypesModule;
    public roomTypesState = this.$store.state.investmentsState.subjectRoomTypesState;
    public actionsTypes = acceptanceTemplateListModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new AcceptanceTemplateListDataRequest();

    get formTitle() {
        return this.edit ? 'Edytuj listę' : 'Dodaj listę';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.createAcceptanceTemplateListForm;
         }
}
