
import FormComponent from '@/shared/components/layout/form/form-component';

import Vue from 'vue';
import Component from 'vue-class-component';
import {AcceptanceNoteDataRequest} from '../../shared/requests/acceptance-note-data-request';
import {acceptanceNotesModule} from '../../shared/state/submodules';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {AcceptancePoint} from '../../shared/models/acceptance-point';
import {Acceptance} from '../../shared/models/acceptance';
import {acceptancesModule} from '../../shared/state/module';
import TicketsPicker from '@/modules/tickets/components/tickets-picker.vue';
import Ticket from '@/modules/tickets/components/kanban/ticket.vue';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import {ticketsModule} from '@/modules/tickets/shared';
import TicketForm from '@/modules/tickets/components/form.vue';
import NoteEntryDataForm from '@/modules/acceptances/components/notes/note-entry-data.vue';
import AcceptancePlan from '@/modules/acceptances/components/plan.vue';
import SelectPointForm from '@/modules/acceptances/components/notes/select-point.vue';
import {Watch} from 'vue-property-decorator';
import {AcceptanceNote} from '@/modules/acceptances/shared/models/acceptance-note';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        TicketsPicker,
        CardPicker,
        Ticket,
        TicketForm,
        NoteEntryDataForm,
        SelectPointForm,
    },
    props: {
        list: Object,
        point: Object,
    },
})
export default class AcceptanceNoteForm extends FormComponent<AcceptanceNoteDataRequest> {
    public store = this.$store.state.acceptancesState.acceptanceNotesState;
    public actionTypes = acceptanceNotesModule.actionsTypes;
    public storeAction = this.actionTypes.STORE_ITEM;
    public updateAction = this.actionTypes.UPDATE_ITEM;

    public Ticket = Ticket;
    public ticketsStore = this.$store.state.ticketsState;
    public ticketsActionsTypes = ticketsModule.actionsTypes;

    public acceptanceStore = this.$store.state.acceptancesState;

    public currentStep = 1;
    public badRequest = false;
    public createdNote: AcceptanceNote | {} = {};

    get acceptance() {
        return this.acceptanceStore.current;
    }

    public getFormComponent(step: number) {
        switch (step) {
            case 1:
                return 'NoteEntryDataForm';
            case 2:
                return 'SelectPointForm';
        }
    }

    public goBack() {
        if (this.currentStep === 1) {
            this.$emit('canceled');
        } else {
            this.currentStep--;
        }
    }

    public created() {
        //
    }

    public cancelForm() {
        this.initFocus();
        callSuper(this, 'cancelForm', );
    }

    public closeForm() {
        this.$emit('saved', {data: this.createdNote});
        this.initFocus();
    }

    public initFocus() {
        this.createdNote = {} as AcceptanceNote;
        this.currentStep = 1;
    }


    @Watch('currentStep')
    public onCurrentStepChange(val: number) {
        if (val === 3) {
            this.submitForm(null, false);
        }
    }

    public async submitForm(e: any, next: any) {
        this.$store.dispatch(this.storeAction, this.requestParams)
            .then(({data}: any) => {
                this.createdNote = data;
            });
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj uwagę' : 'Dodaj uwagę';
    }
}
