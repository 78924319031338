
import MenuItem from './toolbar-item.vue';
import Vue from 'vue';
import { Editor } from '@tiptap/vue-2';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FontColorPicker from './font-color-picker.vue';
import SpecialCharactersPicker from './special-characters-picker.vue';

@Component({
    components: {
        MenuItem,
        FontColorPicker,
        SpecialCharactersPicker,
    },
})
export default class WysiwygToolbar extends Vue {
    @Prop(Boolean) public readOnly!: boolean;
    @Prop(Boolean) public basicText!: boolean;
    @Prop({
        type: Object,
        required: true,
    })
    public editor!: Editor;

    public items: any[] = [];
    public showColorsMenu = false;
    public showSpecialCharactersPicker = false;

    public created() {
        this.items = [
            {
                icon: 'mdi-format-bold',
                title: 'Bold',
                action: () => this.editor.chain().focus().toggleBold().run(),
                isActive: () => this.editor.isActive('bold'),
            },
            {
                icon: 'mdi-format-italic',
                title: 'Italic',
                action: () => this.editor.chain().focus().toggleItalic().run(),
                isActive: () => this.editor.isActive('italic'),
            },
            {
                icon: 'mdi-format-underline',
                title: 'Underline',
                action: () => this.editor.chain().focus().toggleUnderline().run(),
                isActive: () => this.editor.isActive('underline'),
            },
            {
                icon: 'mdi-format-strikethrough',
                title: 'Strike',
                action: () => this.editor.chain().focus().toggleStrike().run(),
                isActive: () => this.editor.isActive('strike'),
            },
            {
                icon: 'mdi-format-color-text',
                title: 'Color',
                type: 'font-color-picker',
                action: () => (this.showColorsMenu = !this.showColorsMenu),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mdi-format-subscript',
                title: 'Subscript',
                action: () => this.editor.chain().focus().toggleSubscript().run(),
                isActive: () => this.editor.isActive('subscript'),
            },
            {
                icon: 'mdi-format-superscript',
                title: 'Superscript',
                action: () => this.editor.chain().focus().toggleSuperscript().run(),
                isActive: () => this.editor.isActive('superscript'),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mdi-format-header-1',
                title: 'Heading 1',
                action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
                isActive: () => this.editor.isActive('heading', { level: 1 }),
            },
            {
                icon: 'mdi-format-header-2',
                title: 'Heading 2',
                action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
                isActive: () => this.editor.isActive('heading', { level: 2 }),
            },
            {
                icon: 'mdi-format-header-3',
                title: 'Heading 3',
                action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
                isActive: () => this.editor.isActive('heading', { level: 3 }),
            },
            {
                icon: 'mdi-format-paragraph',
                title: 'Paragraph',
                action: () => this.editor.chain().focus().setParagraph().run(),
                isActive: () => this.editor.isActive('paragraph'),
            },
            {
                icon: 'mdi-format-list-bulleted',
                title: 'Bullet List',
                action: () => this.editor.chain().focus().toggleBulletList().run(),
                isActive: () => this.editor.isActive('bulletList'),
            },
            {
                icon: 'mdi-format-list-numbered',
                title: 'Ordered List',
                action: () => this.editor.chain().focus().toggleOrderedList().run(),
                isActive: () => this.editor.isActive('orderedList'),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mdi-minus',
                title: 'Line Break',
                action: () => this.editor.chain().focus().setHorizontalRule().run(),
            },
            {
                icon: 'mdi-format-clear',
                title: 'Clear Format',
                action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
            },
             {
                icon: 'mdi-currency-eur',
                title: 'Special Characters',
                type: 'special-characters-picker',
                action: () => (this.showSpecialCharactersPicker = !this.showSpecialCharactersPicker),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mdi-undo',
                title: 'Undo',
                action: () => this.editor.chain().focus().undo().run(),
            },
            {
                icon: 'mdi-redo',
                title: 'Redo',
                action: () => this.editor.chain().focus().redo().run(),
            },
        ];

        if (this.basicText) {
           this.items = this.items.filter((el) => {
              return !el.title || !(el.title && ['Color', 'Line Break'].includes(el.title));
            });
        }
    }
}
