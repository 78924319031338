
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import AcceptanceCategoryForm from '@/modules/acceptances/components/templates/points-categories/form.vue';

import { AcceptanceDataRequest } from '@/modules/acceptances/shared/requests/acceptance-data-request';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import {
    acceptancePointsCategoriesModule,
    acceptanceTemplatePointsModule,
    acceptanceTemplatesModule,
} from '@/modules/acceptances/shared/state/submodules';
import { AcceptanceTemplatePointDataRequest } from '@/modules/acceptances/shared/requests/acceptance-template-point-data-request';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { AcceptanceTemplatePoint } from '@/modules/acceptances/shared/models/acceptance-template-point';
import { AcceptancePointCategory } from '@/modules/acceptances/shared/models/acceptance-point-category';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { categoriesModule } from '@/modules/changes/shared/state/submodules';
import { AcceptancePoint } from '@/modules/acceptances/shared/models/acceptance-point';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { AutoCompleteWithAddNew, FormActions, NameReduce },
})
export default class AcceptanceTemplatePointForm extends FormComponent<AcceptanceTemplatePointDataRequest> {
    public actionsTypes = acceptanceTemplatePointsModule.actionsTypes;
    public store = this.$store.state.acceptancesState.templatePointsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public categoryShowAction = acceptancePointsCategoriesModule.actionsTypes.SHOW_ITEM;
    public requestData = new AcceptanceTemplatePointDataRequest();

    public pointsCategoriesModule = acceptancePointsCategoriesModule;
    public pointsCategoriesState = this.$store.state.acceptancesState.acceptancePointsCategoriesState;
    public selectedCategory: AcceptancePointCategory = {} as AcceptancePointCategory;
    public CategoryForm = AcceptanceCategoryForm;

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj punkt' : 'Dodaj punkt';
    }

    public initForm() {
        this.form = this.$refs.createAcceptanceTemplateForm;
         }
    public formCallback() {
        this.itemData = {} as AcceptancePoint;
    }
}
