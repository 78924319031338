
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import { TicketCategoryDataRequest, categoriesModule } from '@/modules/tickets/shared';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

@Component({
    components: { FormActions, TextContrast, IconContrast },
})
export default class CategoryForm extends FormComponent<TicketCategoryDataRequest> {
    public actionsTypes = categoriesModule.actionsTypes;
    public store: any = this.$store.state.ticketsState.categoriesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new TicketCategoryDataRequest();

    public swatches: Array<[string, string]> = [
        ['#753BBD', '#147BD1'],
        ['#2DC84D', '#FF7F41'],
        ['#F7EA48', '#ea97ad'],
        ['#4A6695', '#E03C31'],
        ['#B7CC33', '#FF00A5'],
    ];

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj kategorie' : 'Dodaj kategorie';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }

    @Watch('itemData.color')
    public ifColorObject() {
        if (!(typeof this.itemData.color === 'string')) { this.itemData.color = '#147BD1'; }
    }
}
