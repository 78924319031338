
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import { acceptancePointsCategoriesModule } from '@/modules/acceptances/shared/state/submodules';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

import { AcceptancePointCategoryDataRequest } from '@/modules/acceptances/shared/requests/acceptance-point-category-data-request.';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import { httpClient, logger } from '@/shared/services';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { AutoCompleteWithAddNew, FormActions, IconChooser },
})
export default class AcceptanceTemplatePointForm extends FormComponent<AcceptancePointCategoryDataRequest> {
    public actionsTypes = acceptancePointsCategoriesModule.actionsTypes;
    public store = this.$store.state.acceptancesState.acceptancePointsCategoriesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new AcceptancePointCategoryDataRequest();

    public icons = [];
    public iconsLoading = false;
    public created() {
        const url = `/api/v1/acceptances/point-categories/icons`;
        this.iconsLoading = true;
        httpClient
            .get(url)
            .then((data) => {
                this.icons = data.data.data;
            })
            .catch((err) => {
                logger.log(err);
            })
            .finally(() => {
                this.iconsLoading = false;
            });
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj Kategorie' : 'Dodaj Kategorie';
    }

    public initForm() {
        this.form = this.$refs.createAcceptancePointCategoryForm;
         }
}
