
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { AcceptanceNote } from '@/modules/acceptances/shared/models/acceptance-note';
import { acceptanceNotesModule } from '@/modules/acceptances/shared/state/submodules';
import { Component, Inject, Prop } from 'vue-property-decorator';
import Ticket from '@/modules/tickets/components/kanban/ticket.vue';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import mime from 'mime';
import SelectMediaList from '@/modules/media/components/select.vue';
import {ModuleState} from '@/modules/auth/shared/state';
import {InvestmentSubjectMediaFile} from '@/modules/investments/shared/models/subjects/investment-subject-media-file';
import {
    InvestmentSubjectMediaFileDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-media-file-data-request';
import {investmentSubjectMediaFilesModule} from '@/modules/investments/shared/state/submodules';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import StatusPicker from '@/modules/tickets/components/pickers/status-picker.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';

@Component({
    components: {
        LinkedEvents,
        StatusPicker,
        Ticket,
        SelectMediaList,
        NameReduce,
        TooltipBtn,
    },
})
export default class NoteDetails extends DetailsComponent<AcceptanceNote> {
    get subjectId() {
        return this.$store.state.acceptancesState.current.investment_subject_id || 0;
    }

    @Prop(Object) public assignable!: any;
    @Prop(Boolean) public notDialog!: boolean;
    @Prop({ default: true }) public showTicket!: boolean;

    public actionTypes = acceptanceNotesModule.actionsTypes;
    public showAction = this.actionTypes.SHOW_ITEM;
    public store = this.$store.state.acceptancesState.acceptanceNotesState;

    public currentView = 'ticket';

    public files: MediaFile[] = [];
    public currentFile: number = 0;
    public mediaSelectDialog = false;
    public mediaActionsTypes = investmentSubjectMediaFilesModule.actionsTypes;
    public mediaMutationsTypes = investmentSubjectMediaFilesModule.mutationsTypes;
    public mediaFetchAction = this.mediaActionsTypes.FETCH_DATA;
    public mediaStoreAction = this.mediaActionsTypes.STORE_ITEM;
    public mediaStore = this.$store.state.investmentsState.subjectMediaFilesState;

    public isMobile = window.innerWidth < 1264;

    public created() {
        this.$store.dispatch(this.actionTypes.SHOW_ITEM , { id: this.assignable.id }).then(({data}) => {
            this.item = data.find((el: any) => el.id === this.assignable.id);
        });
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`acceptances.notes.${action}`);
    }

    public onPreSaveMedia(mediaFiles: any, cb: () => void) {
        const subjectMediaRequest: InvestmentSubjectMediaFileDataRequest = {} as InvestmentSubjectMediaFileDataRequest;
        subjectMediaRequest.investment_subject_id = this.subjectId || 0;
        subjectMediaRequest.investment_subject_media_files = mediaFiles.map(({id}: any) => {
            return {media_file_id: id};
        });
        this.$store.dispatch(this.mediaActionsTypes.STORE_ITEM, subjectMediaRequest).then(() => {
            this.$store.dispatch(this.mediaActionsTypes.FETCH_DATA, {
                filters: {investment_subject_id: this.subjectId},
            });
        });
    }

    public mediaSelected(e: any) {
        const files = e.map(({id}: MediaFile) => id);
        this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {id: this.item.id, attachments: files}).then(() => {
            this.item.attachments = e;
        });
        this.mediaSelectDialog = false;
        this.$forceUpdate();
    }

    public onResize() {
        this.isMobile = window.innerWidth < 1264;
    }

    get mainPhoto() {
        return this.item && this.item.attachments
            ? this.item.attachments.find((file: MediaFile) => /(gif|jpe?g|bmp|png)/.test(file.extension))
            : null;
    }

    public playerOptions(item: MediaFile) {
        return {
            sources: [
                {
                    type: mime.getType(item.extension),
                    src: item.video_url,
                },
            ],
            autoplay: 'play',
            muted: true,
            fluid: true,
            poster: item.thumbnail ? item.thumbnail : '/assets/photo.png',
        };
    }
}
