import {ListFilter} from '@/shared/types';

export const acceptancesListFilter: ListFilter[] = [
    {
        name: 'status', label: 'Status', type: 'select', data: [
            {name: 'new', label: 'Nowy', value: false},
            {name: 'in-progress', label: 'W trakcie', value: false},
            {name: 'approved', label: 'Zatwierdzony', value: false},
        ],
    },
];

export const templatePointsListFilter: ListFilter[] = [
    {name: 'acceptance_point_category_id', type: 'search', label: 'Kategoria', description: 'Wybierz Kategorie'},
];
