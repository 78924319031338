
import { Component, Prop, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import { tagsModule, TicketTagDataRequest } from '@/modules/tickets/shared';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import { DataRequest } from '@/shared/types';
import { callSuper } from '@/shared/helpers';
import _ from 'lodash';

@Component({
    components: { FormActions, IconChooser, TextContrast, IconContrast, NameReduce },
})
export default class TagForm extends FormComponent<DataRequest> {
    @Prop(Object) public actionsTypes!: any ;
    @Prop(Object) public state!: any ;
    @Prop({type: Boolean, default: false}) public noRequest!: boolean;


    // Using accessors for abstract properties to make use of props (never assign abstracts directly from prop!)

    // @ts-ignore
    get store() {
        return this.state;
    }
    // @ts-ignore
    get storeAction() {
        return this.actionsTypes.STORE_ITEM;
    }
    // @ts-ignore
    get updateAction() {
        return this.actionsTypes.UPDATE_ITEM;
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj tag' : 'Dodaj tag';
    }

    public requestData = new DataRequest();

    public swatches: Array<[string, string]> = [
        ['#753BBD', '#147BD1'],
        ['#2DC84D', '#FF7F41'],
        ['#F7EA48', '#ea97ad'],
        ['#4A6695', '#E03C31'],
        ['#B7CC33', '#FF00A5'],
    ];

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;

        if (!this.itemData.color) {
            this.itemData.color = this.getRandomColor();
        }
    }

     public async submitForm(e: any, next?: boolean) {
        if (!this.noRequest) {
            callSuper(this, 'submitForm', e, next ?? false);
            return;
        }

        this.$emit('create', _.clone(this.itemData));
     }

    @Watch('itemData.color')
    public ifColorObject() {
        if (!(typeof this.itemData.color === 'string')) {
            this.itemData.color = this.getRandomColor();
        }
    }

    private getRandomColor() {
        return this.swatches[Math.floor(Math.random() * this.swatches.length)][Math.round(Math.random())];
    }
}
