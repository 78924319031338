
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
    props: {
        step: Number,
        valid: Boolean,
        optional: Boolean,
    },
})
export default class SubFormActions extends Vue {
    get canGoBack() {
        return this.$props.step > 1;
    }
}
