
import {Component, Vue, Watch} from 'vue-property-decorator';
import SelectMediaList from '@/modules/media/components/select.vue';
import mime from 'mime';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import {getIcon} from '@/modules/media/shared/helpers';

@Component({
    components: {
        SelectMediaList,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        items: Array,
    },
})
export default class MediaCarousel extends Vue {
    public current: number = 0;
    public value!: number;
    public items!: MediaFile[];
    public player: any;
    public getIcon = getIcon;

    public playerOptions(item: MediaFile) {
        return {
            sources: [{
                type: mime.getType(item.extension),
                src: item.video_url,
            }],
            aspectRatio: '16:9',
            fill: true,
            language: 'pl',
            poster: item.thumbnail ? item.thumbnail : '/assets/photo.png',
        };
    }

    public itemChanged() {
        this.$emit('input', this.current);
        this.stopVideo();
    }

    public stopVideo() {
        if (this.player && !this.player.paused()) {
            this.player.pause();
        }
    }

    public bytesToSize(bytes: number) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return '0 Byte';
        }
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    @Watch('value', {deep: true})
    public onValueChange() {
        this.current = this.value;
    }

    @Watch('items')
    public onSelectedChange() {
        this.current = this.items.length - 1;
    }

}
