

import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {investmentSubjectRoomsListHeaders} from '@/modules/investments/shared/config';
import {ModuleState} from '@/shared/state/template/module-state';
import {
    investmentSubjectPlansModule,
    investmentSubjectRoomsModule
} from '@/modules/investments/shared/state/submodules';
import ItemsTable from '@/shared/components/layout/list.vue';
import InvestmentSubjectRoomDetails from '@/modules/investments/components/objects/subjects/rooms/details.vue';
import InvestmentPlanForm from '@/modules/investments/components/plans/form.vue';

import {
    InvestmentSubjectPlanDataRequest
} from '@/modules/investments/shared/requests/investment-subject-plan-data-request';
import {InvestmentSubjectPlan} from '@/modules/investments/shared/models/investment-subject-plan';

import StoreyPlan from '@/modules/investments/components/plans/plan-select.vue';
import {InvestmentSubjectRoomsState} from '@/modules/investments/shared/state/state/rooms';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { SaveResponse } from '@/shared/types';

@Component({
    components: {
        ItemsTable,
        InvestmentSubjectRoomDetails,
        InvestmentPlanForm,
        ActionConfirmDialog,
        StoreyPlan,
    },
    props: {
        subjectId: Number,
    },
})
export default class InvestmentSubjectPlanList extends ListComponent<InvestmentSubjectPlan,
    InvestmentSubjectPlanDataRequest> {
    public actionsTypes = investmentSubjectPlansModule.actionsTypes;
    public mutationTypes = investmentSubjectPlansModule.mutationsTypes;
    public roomsMutations = investmentSubjectRoomsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers = investmentSubjectRoomsListHeaders;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public props = investmentSubjectPlansModule.moduleProps;
    public subjectId!: number;
    public roomsState: InvestmentSubjectRoomsState = this.$store.state.investmentsState.subjectRoomsState;
    public store: ModuleState<InvestmentSubjectPlan,
        InvestmentSubjectPlanDataRequest> = this.$store.state.investmentsState.subjectPlansState;
    public showMenu: boolean = false;
    public x: number = 0;
    public y: number = 0;
    public requestData = new InvestmentSubjectPlanDataRequest();

    public created() {
        this.id = this.subjectId;
        this.fetchData();
    }

    get editedRoom() {
        return this.$store.state.investmentsState.subjectRoomsState.editedItem;
    }

    set editedRoom(val) {
        this.$store.commit(this.roomsMutations.UPDATE_EDITED, val);
    }

    public itemData() {
        return {
            investment_subject_id: this.subjectId,
        };
    }

    public planSaved(e: SaveResponse<InvestmentSubjectPlan>) {
        this.$emit('saved');
        if (this.roomsState.formDialog) {
            this.showDialog = true;
            this.current = Object.assign({}, e.data);
            this.roomsState.formDialog = false;
            this.pointSelecting = true;
        }
        this.itemSaved();
    }

    get pointSelecting() {
        return this.roomsState.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.roomsMutations.UPDATE_POINT_SELECTING, value);
    }

    get columns() {
        switch (this.$vuetify.breakpoint.name) {
            case 'lg':
                return 2;
            case 'xl':
                return 3;
            default:
                return 3;
        }
    }

    public openMenu(e: MouseEvent, item: InvestmentSubjectPlan) {
        e.preventDefault();
        this.current = item;
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
            this.showMenu = true;
        });
    }
}

