
import {
    AcceptanceTemplatePointDataRequest
} from '@/modules/acceptances/shared/requests/acceptance-template-point-data-request';
import {acceptanceTemplatePointsModule} from '@/modules/acceptances/shared/state/submodules';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import Component from 'vue-class-component';

@Component({
    props: {
        id: Number,
    },
})
export default class AcceptancePointDetails extends DetailsComponent<AcceptanceTemplatePointDataRequest> {
    public actionsTypes = acceptanceTemplatePointsModule.actionsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public store = this.$store.state.acceptancesState.templatePointsState;

    public created() {
        //
    }
}
