
import Vue from 'vue';
import Component from 'vue-class-component';
import {contrast, hexToRgb} from '@/shared/helpers/colors';
import router from '@/router';
import {Ticket, ticketsModule} from '@/modules/tickets/shared';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import UserPicker from '@/modules/tickets/components/pickers/user-picker.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import {TicketsState} from '../../shared/state/state';
import {Inject} from 'vue-property-decorator';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {User} from '@/modules/admin/shared/models/user';
import {logger} from '@/shared/services';

@Component({
    components: {
        ActionConfirmDialog,
        UserPicker,
        NameReduce,
    },
    props: {
        title: String,
        name: String,
        description: String,
        priority: Object,
        created_at: String,
        id: Number,
        due_date: String,
        reporter: Object,
        assigned_user: Object,
        category: Object,
        tags: Array,
        noClickEvents: Boolean,
        noExpanding: Boolean,
        draggable: Boolean,
        editable: {
            type: Boolean,
            default: true,
        },
    },
})
export default class TicketCard extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    public $refs!: {
        ticketRef: HTMLDivElement;
    };
    public store: TicketsState = this.$store.state.ticketsState;
    public actionsTypes = ticketsModule.actionsTypes;
    public mouseOver = false;
    public assignedUser = {} as User;
    public assignedUserLoading = false;
    public items = [
        {
            icon: 'mdi-card-text',
            title: 'Zobacz więcej',
            action: () => {
                router.push(`/tickets/${this.$props.id ? this.$props.id : ''}`);
            },
        },
        {
            icon: 'mdi-delete',
            iconColor: 'error',
            title: 'Usuń',
            action: this.showRemoveDialog,
        },
    ];

    public removeDialog = false;

    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');

    get isAnyTicketDragged() {
        return this.store.isAnyTicketDragged;
    }

    set isAnyTicketDragged(value) {
        this.store.isAnyTicketDragged = value;
    }

    get currentUserId() {
        return this.$store.state.authState.user.id;
    }

    public mounted() {
        this.assignedUser = this.$props.assigned_user;
    }

    public showRemoveDialog() {
        this.removeDialog = true;
    }

    public closeRemoveDialog() {
        this.removeDialog = false;
    }

    public deleteTicket() {
        this.$store.dispatch(this.actionsTypes.REMOVE_ITEM, this.$props.id);
        this.$emit('remove');
        this.removeDialog = false;
    }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }

    public assignUser(e: Promise<ModuleShowPayload<Ticket>>) {
        this.assignedUserLoading = true;
        if (e instanceof Promise<ModuleShowPayload<Ticket>>) {
            e.then((response) => {
                if (response.data && response.data.assigned_user) {
                    this.assignedUser = response.data.assigned_user;
                }
            })
                .catch((err: any) => {
                    logger.error(err);
                    this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
                })
                .finally(() => {
                    this.assignedUserLoading = false;
                });
        }
    }

    public removeAssignedUser() {
        this.assignedUserLoading = true;
        this.$store
            .dispatch(this.actionsTypes.UPDATE_ITEM, {
                id: this.$props.id,
                assigned_user_id: null,
            })
            .then((val) => {
                this.assignedUser = val.data.assigned_user;
                this.assignedUserLoading = false;
            });
    }

    public onTicketClick(e: any) {
        router.push(`/tickets/${this.$props.id ? this.$props.id : ''}`);
    }
}
