
import Vue from 'vue';
import Component from 'vue-class-component';
import { DataRequest, ItemData } from '@/shared/types';
import { Prop, Watch } from 'vue-property-decorator';
import { nextTick } from 'process';
import { ModuleState } from '@/shared/state/template/module-state';
import { remove } from 'lodash';

@Component
export default class CardPicker<T extends ItemData, R extends DataRequest> extends Vue {
    @Prop(String) public label!: string;
    @Prop(Boolean) public returnObject!: boolean;
    @Prop(Boolean) public returnId!: boolean;
    @Prop(Boolean) public haveRooms!: boolean;
    @Prop(Object) public customFilters!: object;
    @Prop([Object, Number]) public value!: any;
    @Prop(ModuleState) public store!: ModuleState<T, R>;
    @Prop(String) public fetchAction!: string;
    @Prop(String) public showAction!: string;
    @Prop(Boolean) public disabled!: boolean;
    @Prop(Number) public fetchId!: number;
    @Prop(Function) public CardComponent!: any;
    @Prop(Boolean) public onlyPicker!: boolean;
    @Prop(Boolean) public showPicker!: boolean;
    @Prop(Boolean) public simple!: boolean;
    @Prop(Boolean) public removeSelected!: boolean;
    @Prop({type: Array, default: () => []}) public itemsToAppend!: any;

    public items = [] as T[];
    public showItems = this.$props.showPicker ? this.$props.showPicker : false;

    public timer: ReturnType<typeof setTimeout> | undefined;
    public isLoading = false;
    public filters: any = this.$props.customFilters ? { ...this.$props.customFilters } : {};
    public search: string = '';

    get selectedItem() {
        return this.$props.value ? this.store.current : ({} as T);
    }

    set selectedItem(val) {
        this.showItems = false;
        this.store.current = val;
        this.$emit('input', val);
    }


    public beforeMount() {
        if (!this.$options.components) {
            return;
        }
        this.$options.components.ItemCardComponent = this.$props.CardComponent;
    }

    public mounted() {
        this.fetchData();
    }

    public fetchData() {
        if (this.isLoading) {
            return;
        }

        this.items = [];
        this.isLoading = true;

        let itemsToAppend = this.$props.itemsToAppend;
        if (this.filters.search && this.filters.search.length > 0) {
            itemsToAppend = itemsToAppend.filter((item: any) =>
                item.name.toLowerCase().includes(this.filters.search.toLowerCase()),
            );
        }

        this.$store
            .dispatch(this.fetchAction, { id: this.$props.fetchId, filters: this.filters ? this.filters : {} })
            .then(({ data }: any) => {
                this.items = itemsToAppend ? [...itemsToAppend, ...data] : data;
                if (this.$props.haveRooms) {
                    this.items.map((item: any) => {
                        if (item.rooms_count === 0) {
                            item.disabled = true;
                        }
                        return item;
                    });
                }
                this.isLoading = false;
            });
    }

    public selectItem(item: T) {
        this.showItems = false;
        if (this.$props.returnId) {
            this.$emit('input', item.id);
        } else {
            this.$emit('input', item);
        }
    }

    public onOverlayClick() {
        this.showItems = false;
    }


    public displayItems() {
        this.showItems = true;
    }

    @Watch('showItems')
    public onShowItemsChange(val: boolean) {
        this.$emit('update:show-picker', val);
    }
    @Watch('showPicker')
    public onShowPickerChange(val: boolean) {
        this.showItems = val;
    }

    @Watch('customFilters', { deep: true })
    public onFilterChange(val: any) {
        this.items = [];
        this.filters = val;
        this.fetchData();
    }

    @Watch('value', {immediate: true})
    public onValueChage(value: any) {
        if (typeof this.$props.value === 'number') {
            this.isLoading = true;
            this.$store.dispatch(this.showAction, value).finally(() => {
                this.isLoading = false;
            });
        } else if (!value) {
            this.store.current = {} as T;
        }
    }

    @Watch('search')
    public sendRequestOnSearch(value: string) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.filters.search = value;
        this.timer = setTimeout(this.fetchData, 300);
    }
    @Watch('showItems')
    public onshowItems(value: boolean) {
        nextTick(() => {
            if (value) {
                (this.$refs.search as HTMLElement).focus();
            }
        });
    }

}
