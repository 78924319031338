
import { Editor } from '@tiptap/vue-2';
import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';

@Component
export default class SpecialCharactersPicker extends Vue {
    @VModel() public showSpecialCharactersPicker!: boolean;
    @Prop({
        type: Object,
        required: true,
    })
    public editor!: Editor;
    @Prop({
        type: String,
        required: true,
    })
    public icon!: string;
    @Prop({
        type: String,
        required: true,
    })
    public title!: string;
    @Prop({
        type: Function,
        required: true,
    })
    public action!: string;
    @Prop({
        type: Function,
        default: () => true,
    })
    public isActive!: () => boolean;
    @Prop(Boolean) public readOnly!: boolean;

    public characters =
        '⌘«»‹›‘’“”„‚❝❞£¥€$¢¬¶@§®©™°×π±√‰Ω∞≈÷~≠¹²³½¼¾‐–—|⁄\\[]{}†‡…·•●⌥⌃⇧↩¡¿‽⁂∴∵◊※←→↑↓☜☞☝☟✔★☆♺☼☂☺☹☃✉✿✄✈✌✎♠♦♣♥♪♫♯♀♂αßÁáÀàÅåÄäÆæÇçÉéÈèÊêÍíÌìÎîÑñÓóÒòÔôÖöØøÚúÙùÜüŽž';

    public insert(char: string) {
        this.editor.commands.insertContent(char);
    }
}
