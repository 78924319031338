
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mdiArrowLeft, mdiArrowUp} from '@mdi/js';
import {logger} from '@/shared/services';

@Component({})
export default class TopToolbar extends Vue {
    @Prop(String) public title!: string;
    @Prop(Boolean) public loading!: boolean;
    @Prop(Object) public route!: any;
    @Prop({type: Boolean, default: true}) public back!: boolean;
    @Prop(Boolean) public preventBack!: boolean;
    @Prop(Boolean) public hasMoreActions!: boolean;
    public icons: object = {
        mdiArrowLeft,
        mdiArrowUp,
    };
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public goBack() {
        if (this.preventBack) {
            this.$emit('click:back');
            return;
        }
        if (this.route) {
            return this.$router.push(this.route).catch((err) => logger.error(err));
        }

        this.$router.back();
    }

    public goToItem(routeName: string, routeParams: any) {
        this.$router.push({name: routeName, params: routeParams})
            .catch((err: any) => logger.error(err));
    }
}
