import FormComponent from '@/shared/components/layout/form/form-component';
import {categoriesModule} from '@/modules/tickets/shared/state/settings/category';
import {statusesModule} from '@/modules/tickets/shared/state/settings/status';
import {prioritiesModule} from '@/modules/tickets/shared/state/settings/priority';
import {tagsModule} from '@/modules/tickets/shared/state/settings/tags';
import {ModuleState} from '@/shared/state/template/module-state';
import {
    TicketCategory,
    TicketCategoryDataRequest,
    TicketDataRequest,
    TicketPriority,
    TicketPriorityDataRequest,
    TicketStatus,
    TicketStatusDataRequest,
    TicketTag,
    TicketTagDataRequest
} from '@/modules/tickets/shared';

export class TicketFormProperties extends FormComponent<TicketDataRequest> {
    // Modules
    public categoriesModule = categoriesModule;
    public tagsModule = tagsModule;
    public prioritiesModule = prioritiesModule;
    public statusesModule = statusesModule;

    // States
    public statusesState: ModuleState<TicketStatus, TicketStatusDataRequest> =
        this.$store.state.ticketsState.statusesState;
    public prioritiesState: ModuleState<TicketPriority, TicketPriorityDataRequest> =
        this.$store.state.ticketsState.prioritiesState;
    public tagsState: ModuleState<TicketTag, TicketTagDataRequest> =
        this.$store.state.ticketsState.tagsState;
    public categoriesState: ModuleState<TicketCategory, TicketCategoryDataRequest> =
        this.$store.state.ticketsState.categoriesState;
}
