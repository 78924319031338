
import {ModuleState} from '@/shared/state/template/module-state';
import { investmentSubjectPlansModule } from '@/modules/investments/shared/state/submodules';
import Vue from 'vue';
import Component from 'vue-class-component';

import {Watch} from 'vue-property-decorator';
import {MediaFile} from '@/modules/media/shared/models/media-file';

import { InvestmentSubjectPlanDataRequest } from '@/modules/investments/shared/requests/investment-subject-plan-data-request';
import { InvestmentSubjectPlan } from '@/modules/investments/shared/models/investment-subject-plan';

import InvestmentSubjectPlanList from '@/modules/investments/components/plans/list.vue';

import PlanFileInput from '@/modules/investments/components/plans/plan-file-input.vue';


@Component({
    props: {
        value: Number,
        subjectId: Number,
        multiple: Boolean,
        icon: String,
        label: String,
    },
    components: {
        InvestmentSubjectPlanList,
        PlanFileInput,
    },
})
export default class AcceptanceSelectSubjectRelatedMedia extends Vue {
    public actionsTypes = investmentSubjectPlansModule.actionsTypes;
    public mutationsTypes = investmentSubjectPlansModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public showAction = this.actionsTypes.SHOW_MEDIA;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public store: ModuleState<InvestmentSubjectPlan, InvestmentSubjectPlanDataRequest> =
        this.$store.state.investmentsState.subjectPlansState;
    public item: any = null;

    public mounted() {
        if (this.$props.value) {
            this.$store.dispatch(this.showAction, this.$props.value).then(({data}) => {
                this.item = data;
            });
        }
    }

    @Watch('item', {deep: true})
    public onItemChange(val: MediaFile) {
        if (this._.isEmpty(val)) {
            return;
        }
        if (Array.isArray(val)) {
            this.$emit('input', val.map(({id}) => id));
        } else {
            this.$emit('input', val ? val.id : null);
        }
    }

    // public onPreSave(mediaFiles: any, cb: () => void) {
    //     const subjectMediaRequest: InvestmentSubjectMediaFileDataRequest
    //      = {} as InvestmentSubjectMediaFileDataRequest;
    //     const subjectMediaRequest: InvestmentSubjectPlanDataRequest = {} as InvestmentSubjectPlanDataRequest;

    //     subjectMediaRequest.investment_subject_id = this.$props.subjectId || 0;
    //     subjectMediaRequest.investment_subject_media_files = mediaFiles.map(({id}: any) => {
    //         return {media_file_id: id};
    //     });
    //     // this.$store.dispatch(this.actionsTypes.STORE_ITEM, subjectMediaRequest).then(() => {
    //     //     this.$store.dispatch(this.actionsTypes.FETCH_DATA, {
    //     //         filters: {investment_subject_id: this.$props.subjectId},
    //     //     });
    //     // });
    // }
}
