
import {Component, Vue} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';
import {ModuleState} from '@/shared/state/template/module-state';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import {acceptancesModule} from '@/modules/acceptances/shared/state/module';
import {errorsToArray} from '@/shared/services';
import { callSuper } from '@/shared/helpers';

@Component
export default class AcceptanceApprove extends FormComponent<AcceptanceDataRequest> {

    public store: ModuleState<Acceptance, AcceptanceDataRequest> = this.$store.state.acceptancesState;
    public actionTypes = acceptancesModule.actionsTypes;
    public errorsToArray = errorsToArray;
    public storeAction = this.actionTypes.APPROVE;
    public updateAction = '';

    get formTitle() {
        return 'Zatwierdź odbiór';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.approveForm;
         }
}
