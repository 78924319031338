
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import moment from 'moment';

@Component({
    props: {
        label: String,
        outlined: Boolean,
        initialDate: String,
        errorMessages: Array,
        disabled: Boolean,
        rules: Array,
        messages: Array,
    },
})
export default class DatePicker extends Vue {
    @Prop({
        type: String,
        default: 'YYYY-MM-DD',
    }) private format!: string;
    private label!: string;
    private initialDate!: string;
    private dateMenu: boolean = false;
    private date: string = '';
    private menu: any = this.$refs.dateMenu as any;

    @Watch('date')
    public OnDateChange() {
        this.$emit('datePicked', this.date);
    }

    get dateParsed() {
        return this.date && this.date !== 'Invalid date' ? moment(this.date).format(this.format) : '';
    }

    @Watch('initialDate')
    public OnInitDateChange() {
        this.date = this.initialDate;
        this.saveDate(this.initialDate);
    }

    public created() {
        this.date = this.initialDate;
        this.saveDate(this.initialDate);
    }

    public saveDate(date?: string) {
        const menu = this.$refs.dateMenu as any;

        if (menu) {
            menu.save(date || this.date);
        }
    }
}
