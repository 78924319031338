import { render, staticRenderFns } from "./details.vue?vue&type=template&id=07ffa5a9&scoped=true&"
import script from "./details.vue?vue&type=script&lang=ts&"
export * from "./details.vue?vue&type=script&lang=ts&"
import style0 from "./details.vue?vue&type=style&index=0&id=07ffa5a9&prod&lang=scss&"
import style1 from "./details.vue?vue&type=style&index=1&id=07ffa5a9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ffa5a9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VContainer,VDialog,VForm,VIcon,VList,VListGroup,VListItem,VListItemAction,VListItemAvatar,VListItemSubtitle,VListItemTitle,VRow,VSlideXReverseTransition,VSpacer})
