
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {acceptanceTemplatesListHeaders} from '@/modules/acceptances/shared/config';
import {AcceptanceTemplate} from '@/modules/acceptances/shared/models/acceptance-template';
import {AcceptanceTemplateDataRequest} from '@/modules/acceptances/shared/requests/acceptance-template-data-request';
import {acceptanceTemplatesModule} from '@/modules/acceptances/shared/state/submodules';
import AcceptanceTemplateForm from '@/modules/acceptances/components/templates/form.vue';

@Component({
    components: {
        AcceptanceTemplateForm,
        ItemsTable,
    },
})
export default class AcceptanceTemplatesList extends ListComponent<AcceptanceTemplate, AcceptanceTemplateDataRequest> {
    public headers: object[] = acceptanceTemplatesListHeaders;
    public actionsTypes = acceptanceTemplatesModule.actionsTypes;
    public mutationTypes = acceptanceTemplatesModule.mutationsTypes;
    public props: ModuleProps = acceptanceTemplatesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.acceptancesState.templatesState;
}
