
import { Component, Prop } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { AcceptanceDataRequest } from '@/modules/acceptances/shared/requests/acceptance-data-request';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import { acceptanceTemplatesModule } from '@/modules/acceptances/shared/state/submodules';
import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { AcceptanceTemplate } from '@/modules/acceptances/shared/models/acceptance-template';
import TemplateListAssigner from '@/modules/acceptances/components/form/template-list-assigner.vue';
import SubFormActions from './sub-form-actions.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import SelectMedia from '@/modules/acceptances/components/form/select-media.vue';
import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import Hint from '@/shared/components/elements/hint.vue';


@Component({
    components: { FormActions, TemplateListAssigner, SubFormActions, MediaFileInput, SelectMedia, TooltipBtn, Hint, },
})
export default class AcceptanceFormEntryData extends Vue {
    @Prop(Object) public itemData!: AcceptanceDataRequest;
    @Prop(Number) public referencePhoto!: number;
    @Prop(Number) public step!: number;

    public actionsTypes = acceptancesModule.actionsTypes;
    public templateTypes = acceptanceTemplatesModule.actionsTypes;
    public subjectTypes = investmentSubjectOperationsModule.actionsTypes;
    public formRules = new FormRules();
    public valid = false;
    public store = this.$store.state.acceptancesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
    public errorMessages: any = {};

    get users() {
        return this.$store.state.adminState.users;
    }

    get loadingTemplates() {
        return this.$store.state.acceptancesState.templatesState.loading;
    }

    get templates() {
        return this.$store.state.acceptancesState.templatesState.data.map((template: any) => {
            if (template.lists_count <= 1) {
                template.disabled = true;
            }
            return template;
        });
    }

    get loadingSubjects() {
        return this.$store.state.investmentsState.subjectOperationsState.loading;
    }

    get subjects() {
        return this.$store.state.investmentsState.subjectOperationsState.data;
    }

    get referenceMedia() {
        return this.referencePhoto;
    }

    set referenceMedia(value) {
        this.$emit('update:reference-photo', value);
    }

    public created() {
        this.$store.dispatch(this.fetchUsersAction, {
            simple: true,
        });
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public changeName() {
        if (this.itemData.investment_subject_id && this.itemData.acceptance_template_id && !this.itemData.name) {
            const subject = this.subjects.find(
                (item: InvestmentSubject) => item.id === this.itemData.investment_subject_id,
            );
            const template = this.templates.find(
                (item: AcceptanceTemplate) => item.id === this.itemData.acceptance_template_id,
            );
            this.itemData.name = `${subject.name} - ${template.name}`;
        }
    }

    public goToEditTemplate(templateId: number) {
        this.store.formDialog = false;
        this.$router.push({ name: 'acceptance-template-details', params: { id: String(templateId) } });
    }
}
