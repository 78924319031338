
import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';

@Component({
    props: {
        loading: Boolean,
        value: [Object, Number, String],
        icons: Array,
        itemValue: {
            type: String,
            default: 'id',
        },
        iconValue: String,
        returnObject: Boolean,
    },
})
export default class IconChooser extends Vue {
    public dialog: boolean = false;
    public returnObject!: boolean;
    public itemValue!: string;
    public icons!: any[];
    public selectedIcon: any = {};
    public value!: any;

    public openDialog() {
        this.selectedIcon = this.item;
        this.dialog = true;
    }

    public closeDialog() {
        this.selectedIcon = this.item;
        this.dialog = false;
    }

    public iconSelected() {
        this.dialog = false;
        this.$emit('input', this.returnObject ? this.selectedIcon : this.selectedIcon[this.itemValue]);
    }

    get item() {
        return this.icons.find((item) => this.value === item[this.itemValue]);
    }
}
