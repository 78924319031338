
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import SelectPlanList from '@/modules/investments/components/plans/select.vue';

import {MediaFile} from '@/modules/media/shared/models/media-file';

@Component({
    components: {
        SelectPlanList,
    },
    props: {
        label: String,
        disabled: Boolean,
        clearable: Boolean,
        filters: Object,
        icon: {
            type: String,
            default: 'mdi-file',
        },
        value: [Array, Number, Object],
        multiple: Boolean,
        error: String,
        errorMessages: Object,
        outlined: Boolean,
        customStore: Object,
        customFetchAction: String,
        FileProperty: String,
        customCreatedItemProperties: Object,
        customMutations: Object,
        customStoreAction: String,
        preSavedFunction: Function,
    },
})
export default class PlanFileInput extends Vue {
    @Prop(String) public mediaFileProperty!: string;
    public planSelectDialog: boolean = false;
    public multiple!: boolean;
    public value!: any;
    public selectedItems = [];

    public mounted() {
        if (this.value) {
            this.selectedItems = !this.multiple ? [this.value] : this.value;
        }
    }

    get selected() {
        return this.selectedItems;
    }

    set selected(val) {
        this.selectedItems = val || [];
        this.$emit('input', this.multiple ? val : (val ? val[0] : null));
    }

    public itemSelected(e: any) {
        this.selected = e;
        this.planSelectDialog = false;
    }

    @Watch('value')
    public onValueChanged() {
        if (this.value) {
            this.selectedItems = !this.multiple ? [this.value] : this.value;
        } else {
            this.selectedItems = [];
        }
    }

    @Watch('selectedItems')
    public onSelectedChanged(val: MediaFile[]) {
        this.$emit('input', this.multiple ? val : (val ? val[0] : [null]));
    }
}
