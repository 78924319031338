
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';
import {acceptancesModule} from '@/modules/acceptances/shared/state/module';
import {acceptanceTemplatesModule} from '@/modules/acceptances/shared/state/submodules';
import { callSuper } from '@/shared/helpers';

@Component({
        components: {FormActions},
    })
    export default class AcceptanceTemplateForm extends FormComponent<AcceptanceDataRequest> {
        public actionsTypes = acceptanceTemplatesModule.actionsTypes;
        public store = this.$store.state.acceptancesState.acceptanceTemplatesState;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public requestData = new AcceptanceDataRequest();

        public created() {
            //
        }

        get formTitle(): string {
            return this.edit ? 'Edytuj szablon protokołu odbioru' : 'Dodaj szablon protokołu odbioru';
        }

        public initForm() {
            this.form = this.$refs.createAcceptanceTemplateForm;
             }
    }
