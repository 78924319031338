
import {Component, Inject, Prop, Watch} from 'vue-property-decorator';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {CalendarEvent} from '@/modules/calendar/shared/models/calendar-event';
import {CalendarEventDataRequest} from '@/modules/calendar/shared/requests/calendar-event-data-request';
import {ModuleProps} from '@/shared/state/template/module-props';
import {CalendarState} from '@/modules/calendar/shared/state/state';
import {calendarModule} from '@/modules/calendar/shared/state/module';
import {DayHeaderContentArg, EventDropArg, EventInput, NowIndicatorContentArg} from '@fullcalendar/vue';
import {Calendar, CalendarOptions, EventApi, EventClickArg, EventSourceApi} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {DateClickArg, EventResizeDoneArg} from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import plLocale from '@fullcalendar/core/locales/pl';
import {eventAssignables, viewTypes} from '@/shared/config/calendar';
import {callSuper, objectToQueryString, parseMoment} from '@/shared/helpers';
import SystemBar from '@/shared/components/elements/system-bar.vue';
import EventForm from './form.vue';
import {SaveResponse} from '@/shared/types';
import EventDetails from './details.vue';
import {ModuleFetchPayload, ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {AxiosResponse} from 'axios';
import {httpClient} from '@/shared/services';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

@Component({
    components: {
        ActionConfirmDialog,
        EventDetails,
        SystemBar,
        TooltipBtn,
        EventForm,
    }
})
export default class LinkedEvents extends ListComponent<CalendarEvent, CalendarEventDataRequest> {
    @Prop(String) public assignableType!: string;
    @Prop(Number) public assignableId!: number;
    @Prop(Object) public itemTemplate!: object;
    @Prop(Object) public item!: any;

    public headers: object[] = [];
    public calendarDialog: boolean = false;
    public store: CalendarState = this.$store.state.calendarState;
    public actionsTypes = calendarModule.actionsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public mutationTypes: { [k: string]: string; } = calendarModule.mutationsTypes;
    public props: ModuleProps = calendarModule.moduleProps;
    public calendar: Calendar | null = null;
    public x: number = 0;
    public y: number = 0;
    public cardX: number = 0;
    public cardY: number = 0;
    public isDragged: boolean = false;
    public currentEvent: EventApi | null = null;
    public editedEvent: EventApi | null = null;
    public currentEventsSource: EventSourceApi | null = null;
    public currentEvents: CalendarEvent[] = [];
    public loadingEvents: boolean = false;
    public viewTypes = viewTypes.filter((view) => ['listWeek', 'timeGridDay'].includes(view.id));

    public calendarOptions() {
        return {
            plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
            initialView: 'listWeek',
            locales: [plLocale],
            locale: 'pl',
            nowIndicator: true,
            timeZone: 'local',
            editable: true,
            dayMaxEvents: true,
            height: '100%',
            snapDuration: {milliseconds: 900000},
            nextDayThreshold: '00:00:00',
            firstDay: 1,
            headerToolbar: {
                left: '',
                center: '',
                right: ''
            },
            businessHours: {
                startTime: '8:00', // a start time (10am in this example)
                endTime: '16:00', // an end time (6pm in this example)
            },

            eventClick: this.showEvent,
            dateClick: this.newEvent,
            eventResize: this.resizeEvent,
            eventDrop: this.dropEvent,

            eventClassNames: ({event}) => {
                return ['calendar-event', ('event-' + event.id)];
            },

            nowIndicatorContent: (arg: NowIndicatorContentArg) => {
                return {html: '<div class="now-circle"></div>'};
            },

            views: {
                day: {
                    viewClassNames: 'day-view',
                },
                list: {
                    listDayFormat: {
                        weekday: 'long',
                        day: 'numeric',
                        omitCommas: true
                    },
                    dayHeaderContent: (arg: DayHeaderContentArg) => {
                        const dayText = arg.text.split(' ');
                        const weekday = document.createElement('span');
                        weekday.innerText = dayText[0];
                        const dayNumber = document.createElement('div');
                        dayNumber.innerText = dayText[1];
                        dayNumber.className = 'day-number mx-auto my-2';
                        dayNumber.onclick = () => {
                            this.calendar?.changeView('timeGridDay');
                            this.calendar?.gotoDate(arg.date);
                        };
                        return {domNodes: [weekday, dayNumber]};
                    },
                }
            },

            eventSources: [
                {
                    color: 'primary',
                    id: 'current-user',
                    events: (fetchInfo, successCallback, errorCallback) => {
                        this.$store.dispatch(this.actionsTypes.FETCH_DATA, {
                            simple: false,
                            filters: {
                                start_date: this.parseDateTime(fetchInfo.start),
                                end_date: this.parseDateTime(fetchInfo.end),
                                assignable_type: this.assignableType,
                                assignable_id: this.assignableId,
                            }
                        })
                            .then((response) => {
                                if (response.data) {
                                    const events = this.items.map((event: CalendarEvent) => this.parseEvent(event));
                                    successCallback(events);
                                }
                            })
                            .catch(errorCallback);
                    },
                },
                {
                    className: 'edited-event',
                    id: 'edited-event',
                    events: [],
                }
            ],
        } as CalendarOptions;
    }

    public eventRemoved(status: number) {
        if (status === 204) {
            if (this.calendar) {
                const calendarEvent = this.calendar.getEventById(String(this.current.id));
                if (calendarEvent) {
                    calendarEvent.remove();
                }
            }
            this.showDialog = false;
        }
    }

    public resizeEvent(e: EventResizeDoneArg) {
        const source = e.event.source;
        const editedEvent = {
            id: Number(e.event.id),
            all_day: e.event.allDay,
        } as CalendarEvent;

        const startDate = new Date(e.event.startStr);
        if (e.startDelta) {
            startDate.setMilliseconds(startDate.getMilliseconds() + e.startDelta.milliseconds);
            startDate.setMonth(startDate.getMonth() + e.startDelta.months);
            startDate.setHours(startDate.getHours() + (e.startDelta.days * 24));
            startDate.setFullYear(startDate.getFullYear() + e.startDelta.years);
        }

        editedEvent.start_date = !editedEvent.all_day ? this.parseDateTime(startDate) : this.parseDate(startDate);

        const endDate = parseMoment(e.event.endStr);
        if (editedEvent.all_day) {
            endDate.subtract(1, 'day');
        }
        editedEvent.end_date = !editedEvent.all_day ?
            this.parseDateTime(endDate.toDate()) :
            this.parseDate(endDate.toDate());

        if (source) {
            switch (source.id) {
                case 'edited-event':
                    this.editedItem.start_date = editedEvent.start_date;
                    this.editedItem.end_date = editedEvent.end_date;
                    this.editedItem.all_day = editedEvent.all_day;
                    break;
                default:
                    this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, editedEvent).then(this.updateCallback);
                    break;
            }
        }
    }

    public updateCallback({data}: ModuleShowPayload<CalendarEvent>) {
        if (data) {
            const event = this.calendar?.getEventById(String(data.id));
            if (event && event.source) {
                let index = -1;
                switch (event.source.id) {
                    case 'current-events':
                        index = this.currentEvents.findIndex((e) => data.id === e.id);
                        this.$set(this.currentEvents, index, data);
                        break;
                    default:
                        index = this.items.findIndex((e) => data.id === e.id);
                        this.$set(this.items, index, data);
                        break;
                }
            }
            if (this.current.id === data.id) {
                this.current = data;
            }
        }
    }

    public beforeDestroy() {
        if (this.calendar) {
            this.calendar.destroy();
        }
        this.closeForm();
        this.showDialog = false;
    }

    public menuChanged(value: boolean) {
        if (!value) {
            this.closeForm();
            this.showDialog = false;
        }
    }

    public itemSaved({data, next}: SaveResponse<CalendarEvent>) {
        this.formDialog = false;
        if (this.calendar) {
            this.calendar.addEvent(this.parseEvent(data), 'current-user');
            const eventIndex = this.items.findIndex((e) => e.id === data.id);
            if (eventIndex !== -1) {
                this.$set(this.items, eventIndex, data);
            } else {
                this.items.push(data);
            }
        }
    }

    public newEvent(arg: DateClickArg) {
        const target = arg.jsEvent.target as Element;
        if (target && !target.classList.contains('fc-daygrid-day-number')) {
            if (!this.formDialog) {
                this.editedItem = {} as CalendarEventDataRequest;
                this.setFormView(arg.dayEl, arg.jsEvent);
                const item = {
                    ...this.eventTemplate(),
                    start_date: this.parseDateTime(arg.dateStr),
                    all_day: arg.allDay,
                    end_date: this.parseDateTime(arg.dateStr),
                } as CalendarEventDataRequest;

                if (!item.all_day) {
                    const date = parseMoment(arg.date);
                    date.add(1, 'hours');
                    item.end_date = this.parseDateTime(date.toDate());
                }

                this.createItem(item);
            } else {
                this.closeForm();
            }
        }
    }

    public dropEvent(e: EventDropArg) {
        const source = e.event.source;
        const editedEvent = {
            id: Number(e.event.id),
            all_day: e.event.allDay,
        } as CalendarEvent;

        const startDate = new Date(e.event.startStr);

        editedEvent.start_date = !editedEvent.all_day ? this.parseDateTime(startDate) : this.parseDate(startDate);

        if (e.event.endStr) {
            const endDate = parseMoment(e.event.endStr);
            if (editedEvent.all_day) {
                endDate.subtract(1, 'day');
            }
            editedEvent.end_date = !editedEvent.all_day ?
                this.parseDateTime(endDate.toDate()) :
                this.parseDate(endDate.toDate());
        }

        if (source) {
            switch (source.id) {
                case 'edited-event':
                    this.editedItem.start_date = editedEvent.start_date;
                    this.editedItem.end_date = editedEvent.end_date;
                    this.editedItem.all_day = editedEvent.all_day;
                    break;
                default:
                    this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, editedEvent).then(this.updateCallback);
                    break;
            }
        }
    }

    public editItem(item: CalendarEvent | CalendarEventDataRequest) {
        this.showDialog = false;
        if (this.currentEvent) {
            this.currentEvent.remove();
        }

        callSuper(this, 'editItem', item);
    }

    public previousWeek() {
        this.showDialog = false;
        this.calendar?.prev();
    }

    public nextWeek() {
        this.showDialog = false;
        this.calendar?.next();
    }

    public setFormView(el: Element, jsEvent?: MouseEvent) {
        const clientReact = el.getBoundingClientRect();
        const cardX = (clientReact.x + clientReact.width) + 500;

        if (this.calendar) {
            const view = this.calendar.view;
            this.cardY = clientReact.y;

            switch (view.type) {
                default:
                    if (cardX < window.innerWidth) {
                        this.cardX = clientReact.x + clientReact.width;
                    } else {
                        this.cardX = (clientReact.x) - 500;
                    }
                    break;
                case 'timeGridDay':
                    this.cardX = (clientReact.x - 500);
                    if (jsEvent) {
                        this.cardY = jsEvent.y;
                    }
                    break;
            }


        }
    }

    public showEvent({event, el}: EventClickArg) {
        if (!this.formDialog) {
            this.currentEvent = event;
            let clickedEvent = null;

            if (event.source) {
                switch (event.source.id) {
                    case 'current-events':
                        clickedEvent = this.currentEvents.find((e) => e.id === Number(event.id));
                        break;
                    default:
                        clickedEvent = this.items.find((e) => e.id === Number(event.id));
                        break;
                }

                if (clickedEvent) {
                    this.setFormView(el);
                    this.showItem(clickedEvent);
                }
            }
        }
    }

    public closeDialog() {
        this.calendarDialog = false;
        this.closeForm();
        this.showDialog = false;
    }

    public createItem(item?: CalendarEventDataRequest) {
        this.showDialog = false;

        callSuper(this, 'createItem', item);

        setTimeout(() => {
            const element = document.getElementsByClassName('event-new')[0];
            if (element) {
                this.setFormView(element);
            }
        }, 100);
    }

    public eventTemplate() {
        if (this.calendar) {
            return {
                ...this.itemTemplate,
                name: '',
                start_date: this.parseDate(this.calendar.currentData.currentDate),
                end_date: this.parseDate(this.calendar.currentData.currentDate),
                all_day: true,
                assignable_type: this.assignableType,
                assignable_id: this.assignableId,
                assignable: this.item,
            };
        }
        return null;
    }

    public eventAssignable(search: string) {
        return eventAssignables.find((assignable) => assignable.type === search);
    }

    public parseEvent(event: CalendarEvent, edit?: boolean): EventInput {
        const endDate = parseMoment(event.end_date);
        if (event.all_day) {
            endDate.add(1, 'day'); // Added because of problems with all_day property
        }

        const parsedEvent: EventInput = {
            id: event.id ? String(event.id) : 'new',
            title: event.name || '',
            start: event.start_date,
            end: endDate.toDate(),
            allDay: event.all_day,
        };

        if (event.assignable_type) {
            const assignableType = this.eventAssignable(event.assignable_type);
            parsedEvent.color = assignableType?.color;
        }

        return parsedEvent;
    }

    public showMenu(event: MouseEvent) {
        this.calendarDialog = true;
        const target = event.target as Element;
        const boundingReact = target.getBoundingClientRect();
        this.x = ((boundingReact.x + 500) < window.innerWidth) ? (boundingReact.x - 250) : (window.innerWidth - 500);
        this.y = boundingReact.top + 36;

        setTimeout(() => {
            this.renderCalendar();
        }, 100);
    }

    public renderCalendar() {
        const calendarEl = document.querySelector<HTMLElement>(`#calendar.calendar-${this.assignableType}-${this.assignableId}`);


        if (calendarEl) {
            this.calendar = new Calendar(calendarEl, this.calendarOptions());
            this.calendar.render();
        }

    }

    public toggleCurrentEvents(value: boolean) {
        if (this.calendar) {
            if (value && !this.currentEventsSource) {
                this.currentEventsSource = this.calendar.addEventSource({
                    id: 'current-events',
                    events: (fetchInfo, successCallback, errorCallback) => {
                        let url = '/api/v1/calendar/events?';

                        url += objectToQueryString({
                            start_date: this.parseDateTime(fetchInfo.start),
                            end_date: this.parseDateTime(fetchInfo.end),
                        });

                        this.loadingEvents = true;

                        httpClient.get(url)
                            .then((response: AxiosResponse<ModuleFetchPayload<CalendarEvent>>) => response.data)
                            .then(({data}: ModuleFetchPayload<CalendarEvent>) => {
                                this.loadingEvents = false;
                                if (data) {
                                    this.currentEvents = data.filter((item) =>
                                        !this.items.find((event) => item.id === event.id)
                                    );
                                    successCallback(
                                        this.currentEvents.map((event: CalendarEvent) => this.parseEvent(event))
                                    );
                                }
                            })
                            .catch((error) => {
                                this.loadingEvents = false;
                                errorCallback(error);
                            });
                    },
                });
            } else {
                if (this.currentEventsSource) {
                    this.currentEventsSource.remove();
                    this.currentEventsSource = null;
                }
            }
        }
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`calendar.${actionName}`);
    }

    @Watch('isDragged')
    public onDraggedChange(value: boolean) {
        if (!value) {
            if (this.showDialog) {
                const element = document.querySelector(`#calendar.calendar-${this.assignableType}-${this.assignableId} .event-${this.current.id}`) as Element;
                this.setFormView(element);
            }
            if (this.formDialog) {
                const element = document.querySelector(`#calendar.calendar-${this.assignableType}-${this.assignableId} .event-${(this.editedItem.id || 'new')}`) as Element;
                this.setFormView(element);
            }
        }
    }

    @Watch('editedItem', {deep: true})
    public editedItemChanged(editedItem: CalendarEventDataRequest) {
        if (this.formDialog && this.calendar) {
            if (!this.editedEvent) {
                this.editedEvent = this.calendar.addEvent(
                    this.parseEvent(editedItem as unknown as CalendarEvent, true),
                    'edited-event'
                );
            } else {
                this.editedEvent.setProp('title', editedItem.name);
                this.editedEvent.setStart(editedItem.start_date);
                if (editedItem.end_date) {
                    const endDate = parseMoment(editedItem.end_date);
                    if (editedItem.all_day) {
                        endDate.add(1, 'day');
                    }
                    this.editedEvent.setEnd(endDate.toDate());
                }
                if ('all_day' in editedItem) {
                    this.editedEvent.setAllDay(editedItem.all_day);
                }
            }
        }
    }

    @Watch('formDialog')
    public formDialogChanged(newVal: boolean) {
        if (this.calendar) {
            if (!newVal) {
                if (this.editedEvent) {
                    this.editedEvent.remove();
                    this.editedEvent = null;
                }
                const editedEvent = this.items.find((item) => item.id === this.editedItem.id);
                if (editedEvent && !this.calendar.getEventById(String(this.editedItem.id))) {
                    this.currentEvent = this.calendar.addEvent(
                        this.parseEvent(editedEvent),
                        'current-user'
                    );
                }
            }
        }
    }
}
