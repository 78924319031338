
import {Component, Prop, Watch} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {acceptancesListHeaders} from '@/modules/acceptances/shared/config';
import {acceptancesModule} from '@/modules/acceptances/shared/state/module';
import AcceptanceForm from '@/modules/acceptances/components/form/form.vue';
import {acceptancesStatuses} from '@/shared/config/acceptances';
import {acceptancesListFilter} from '@/modules/acceptances/shared/config/filters';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import {AcceptanceDataRequest} from '../shared/requests/acceptance-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        AcceptanceForm,
        ItemsTable,
    },
})
export default class AcceptancesList extends ListComponent<Acceptance, AcceptanceDataRequest> {
    @Prop(Number) public subjectId!: number;
    public headers: object[] = acceptancesListHeaders;
    public actionsTypes = acceptancesModule.actionsTypes;
    public mutationTypes = acceptancesModule.mutationsTypes;
    public props: ModuleProps = acceptancesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.acceptancesState;
    public filterConfig = acceptancesListFilter;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    @Watch('formDialog')
    public onFormDialog(val: any) {
        if (val) {
            (this.$refs.container as HTMLElement).style.maxHeight = '100vh';
        } else {
            (this.$refs.container as HTMLElement).style.maxHeight = this.containerDefaultMaxHeight;
        }
    }

    public itemSaved() {
        this.fetchData();
        this.closeForm();
    }

    public statusType(statusType: string) {
        return acceptancesStatuses.find((obj) => obj.id === statusType);
    }


    public goToDetails(id: any) {
        this.closeForm();
        this.$router.replace({name: 'acceptance-details', params: {id}});
    }

    public closeForm() {
        this.editedItem = {} as AcceptanceDataRequest;
        callSuper(this, 'closeForm', );
    }
}
