export class AcceptancePointCategoryDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public category_icon_id: number | null = null;
    // public category_icon_id: number | null = null;

    public name: string = '';
    // tslint:disable-next-line:variable-name
    // public acceptance_template_id: number = 0;
    // tslint:disable-next-line:variable-name
    // public investment_subject_id: number = 0;
}
