
import {Component, Vue, Watch} from 'vue-property-decorator';
import AcceptancePlan from '@/modules/acceptances/components/plan.vue';
import {FormRules} from '@/shared/validation/form-rules';
import SubFormActions from '@/modules/acceptances/components/form/sub-form-actions.vue';
// import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import SelectMedia from '@/modules/acceptances/components/form/select-media.vue';
import {acceptancesModule} from '../../shared/state/module';

@Component({
    components: {
        AcceptancePlan,
        SubFormActions,
        SelectMedia,
    },
    props: {
        itemData: Object,
        list: Object,
        step: Number,
    },
})
export default class SelectPointForm extends Vue {
    public formRules = new FormRules();
    public valid = false;
    public referencePhotoId: number | null = null;
    public actionsTypes = acceptancesModule.actionsTypes;
    public imgFilters = {
        extension: ['gif', 'jpeg', 'jpg', 'bmp', 'png'],
    };

    @Watch('referencePhotoId')
    public onPhotoPicked(val: any) {
        this.$store
            .dispatch(this.actionsTypes.UPDATE_ITEM, {
                id: this.$store.state.acceptancesState.current.id,
                reference_photo_id: val,
            })
            .then((res) => {
                this.$store.state.acceptancesState.current = res.data;
            });
    }

    get isPlanAddedToAcceptance() {
        return (
            this.$store.state.acceptancesState.current.reference_photo &&
            this.$store.state.acceptancesState.current.reference_photo.url
        );
    }

    get subjectId() {
        return this.$store.state.acceptancesState.current.investment_subject_id;
    }
}
