
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import { mdiEmail, mdiPhone } from '@mdi/js';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { FormRules } from '@/shared/validation/form-rules';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import PreventUnload from '@/shared/PreventUnload.vue';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import TicketsForm from '@/modules/tickets/components/form.vue';
import { AcceptancePoint } from '@/modules/acceptances/shared/models/acceptance-point';
import { Acceptance } from '@/modules/acceptances/shared/models/acceptance';
import Vue from 'vue';
import { ModuleState } from '@/shared/state/template/module-state';
import { SaveResponse } from '@/shared/types';
import NoteForm from '@/modules/acceptances/components/notes/form.vue';
import { AcceptanceNote } from '../shared/models/acceptance-note';
import { AcceptanceNoteDataRequest } from '../shared/requests/acceptance-note-data-request';
import { acceptanceListModule, acceptanceNotesModule, acceptancePointsModule } from '../shared/state/submodules';
import AcceptancePlan from '@/modules/acceptances/components/plan.vue';
import TabSelector from './tab-selector.vue';
import AcceptanceNotesList from '@/modules/acceptances/components/notes/list.vue';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import NoteDetails from '@/modules/acceptances/components/notes/details.vue';
import NoteCard from '@/modules/acceptances/components/notes/note-card.vue';
import { AcceptancePointCategory } from '../shared/models/acceptance-point-category';
import AcceptanceApprove from '@/modules/acceptances/components/approve.vue';
import { acceptancesStatuses } from '@/shared/config/acceptances';
import AcceptanceList from '../shared/models/acceptance-list';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';
import UserPicker from '@/modules/acceptances/components/pickers/user-picker.vue';

@Component({
    components: {
        LinkedEvents,
        PreventUnload,
        TicketsForm,
        AutoCompleteWithAddNew,
        AcceptanceNotesList,
        AcceptanceApprove,
        AcceptancePlan,
        NameReduce,
        TopToolbar,
        NoteForm,
        TabSelector,
        CardPicker,
        NoteDetails,
        NoteCard,
        TooltipBtn,
        ObservableWatch,
        UserPicker,
    },
})
export default class AcceptanceDetails extends DetailsComponent<Acceptance> {
    @Prop(Number) declare public id: string;
    public icons: object = {
        mdiPhone,
        mdiEmail,
    };
    public props: ModuleProps = acceptancesModule.moduleProps;
    public actionsTypes = acceptancesModule.actionsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public acceptancesMutations = acceptancesModule.mutationsTypes;
    public notesMutations = acceptanceNotesModule.mutationsTypes;
    public notesActionsTypes = acceptanceNotesModule.actionsTypes;
    public itemData = {};
    public valid = false;
    public updatingItem = false;
    public planDialog = false;
    public notesDialog = false;
    public approveDialog = false;
    public formRules = new FormRules();
    public store = this.$store.state.acceptancesState;
    public notesState: ModuleState<AcceptanceNote, AcceptanceNoteDataRequest> =
        this.$store.state.acceptancesState.acceptanceNotesState;
    public selectedPoint: AcceptancePoint = {} as AcceptancePoint;
    public currentList = 0;
    public acceptanceListState = this.store.acceptanceListState;
    public acceptancePointsState = this.store.acceptancePointsState;
    public acceptanceListActionsTypes = acceptanceListModule.actionsTypes;
    public acceptancePointsActionsTypes = acceptancePointsModule.actionsTypes;
    public areListsLoading = false;
    public showCardPicker = false;
    public notePickerFilters = {};

    get selectedNote() {
        return this.notesState.current;
    }

    set selectedNote(val) {
        this.notesState.current = val;
    }

    get lists() {
        return this.acceptanceListState.data;
    }

    set lists(value: any) {
        this.acceptanceListState.data = value;
    }

    get numberOfActiveNotes() {
        const activeNotes = this.store.notes.filter((el: any) => el.ticket.status.is_open);
        return activeNotes.length;
    }

    get noteFormDialog() {
        return this.notesState.formDialog;
    }

    set noteFormDialog(value) {
        this.$store.commit(this.notesMutations.UPDATE_FORM_DIALOG, value);
    }

    get formDialog() {
        return this.notesState.formDialog;
    }

    set formDialog(value) {
        this.$store.commit(this.notesMutations.UPDATE_FORM_DIALOG, value);
    }

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(val) {
        this.$store.commit(this.acceptancesMutations.UPDATE_EDITED, val);
    }

    get editedNote() {
        return this.notesState.editedItem;
    }

    set editedNote(val) {
        this.$store.commit(this.notesMutations.UPDATE_EDITED, val);
    }

    public created() {
        this.fetchDetails();
        this.fetchLists();
        this.fetchNotes();
    }

    public mounted() {
        this.noteFormDialog = false;
        // this.$store.dispatch(this.notesActionsTypes.FETCH_DATA , {id: this.id})
    }
    public isPermitted(action: string) {
        return this.permissionCheck(`acceptances.${action}`);
    }
    public statusType(statusType: string) {
        return acceptancesStatuses.find((obj) => obj.id === statusType);
    }

    public showNotesPicker(point: AcceptancePoint) {
        this.$store.commit(this.notesMutations.DATA_REQUEST);
        this.notePickerFilters = point
            ? {
                  acceptance_point_id: point.id,
              }
            : {};
        this.$nextTick(() => {
            this.showCardPicker = true;
        });
    }

    public fetchLists() {
        this.areListsLoading = true;
        this.$store.dispatch(this.acceptanceListActionsTypes.FETCH_DATA, { id: this.id }).then(() => {
            this.areListsLoading = false;
        });
    }

    public editAcceptance() {
        this.editedItem = Object.assign(this.item);
        this.formDialog = true;
    }

    public fetchNotes() {
        this.$store.dispatch(this.notesActionsTypes.FETCH_DATA, { id: this.id }).then(({ data }) => {
            this.store.notes = data;
        });
    }

    public openPlan() {
        // this.$store.dispatch(this.notesActionsTypes.FETCH_DATA, { id: this.id }).then(() => {
        this.planDialog = true;
        // });
    }

    public openNotes() {
        this.notesDialog = true;
    }

    public displayNote(note: AcceptanceNote) {
        this.notesDialog = true;
    }

    public categoryPoints(category: number) {
        if (!this.lists) {
            return [];
        }
        return this.lists[this.currentList].points.filter((el: any) => el.acceptance_point_category_id === category);
    }

    public categoryProgress(category: number) {
        return (
            (this.categoryPoints(category).filter((el: AcceptancePoint) => el.checked).length /
                this.categoryPoints(category).length) *
            100
        );
    }

    public notesInCategoryCount(
        active: boolean,
        { id, name }: AcceptancePointCategory,
        listId: number = this.lists[this.currentList].id,
    ) {
        if (!active) {
            let notesCounter = 0;
            const points = this.lists[this.currentList].points.filter(
                (el: any) => el.acceptance_point_category_id === id,
            );
            points.forEach((el: any) => {
                notesCounter += el.notes_count;
            });
            return notesCounter;
        } else {
            return this.store.notes.filter(
                (el: AcceptanceNote) =>
                    el.acceptance_point.acceptance_point_category_id === id &&
                    !el.is_done &&
                    el.acceptance_list_id === listId,
            ).length;
        }
    }

    public pointSave(point: AcceptancePoint) {
        this.$store.dispatch(this.acceptancePointsActionsTypes.UPDATE_ITEM, point).then((response) => {
            if (response) {
                this.$set(
                    this.lists[this.currentList],
                    'is_checked',
                    this.lists[this.currentList].points.every(({ checked }: AcceptancePoint) => checked),
                );

                if (this.item.status === 'new') {
                    this.item.status = 'in-progress';
                }
            }
        });
    }

    public createNote(point: AcceptancePoint) {
        this.selectedPoint = point;
        this.editedNote = {
            acceptance_id: this.item.id,
            name: point.name,
            acceptance_point_id: point.id,
            acceptance_list_id: this.lists[this.currentList].id,
            create_new_ticket: true,
        } as AcceptanceNoteDataRequest;
        this.noteFormDialog = true;
    }

    public closeNoteForm() {
        this.noteFormDialog = false;
    }

    public closeEditForm() {
        this.formDialog = false;
    }

    public itemSaved({ data }: SaveResponse<AcceptanceNote>) {
        this.fetchNotes();
        this.fetchDetails();
        this.formDialog = false;
        if (this.editedNote) {
            this.editedNote = {} as AcceptanceNoteDataRequest;
            this.noteFormDialog = false;
            const pointIndex = this.lists[this.currentList].points.findIndex(
                (e: AcceptancePoint) => data.acceptance_point_id === e.id,
            );
            if (pointIndex !== -1) {
                this.$set(this.lists[this.currentList].points[pointIndex], 'checked', false);
                const notesCount = this.lists[this.currentList].points[pointIndex].notes_count;
                this.$set(this.lists[this.currentList].points[pointIndex], 'notes_count', notesCount + 1);
                this.$set(this.lists[this.currentList], 'is_checked', false);
                this.$set(this.lists[this.currentList].points[pointIndex], 'all_notes_closed', false);
            }
        }
    }
}
