
import { Component, Prop, Watch } from 'vue-property-decorator';
import { calendarModule } from '@/modules/calendar/shared/state/module';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import { CalendarEventDataRequest } from '@/modules/calendar/shared/requests/calendar-event-data-request';
import DateSection from './form/date-section.vue';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import { eventAssignables } from '@/shared/config/calendar';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        NameReduce,
        DateSection,
        FormActions,
        TextContrast,
        Wysiwyg,
    },
})
export default class EventForm extends FormComponent<CalendarEventDataRequest> {
    @Prop(Boolean) public docked!: boolean;
    @Prop(Boolean) public cancel!: boolean;
    @Prop({ type: Boolean, default: false }) public forLinkedEvents!: boolean;
    public actionsTypes = calendarModule.actionsTypes;
    public store = this.$store.state.calendarState;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public requestData = new CalendarEventDataRequest();
    public locationForm: boolean = false;
    public descriptionForm: boolean = false;
    public timeForm: boolean = false;
    public colorForm: boolean = false;
    public eventAssignables = eventAssignables;
    public tab: any = 'startdata';
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

    public created() {
        this.tab = 'startdata';
    }

    get formTitle(): string {
        return this.edit ? '' : '';
    }

    public initForm() {
        this.form = this.$refs.createEventForm;
        this.initFocus();
    }

    public eventAssignable(search: string) {
        return eventAssignables.find((assignable) => assignable.type === search);
    }

    public initFocus() {
        this.timeForm = false;
        this.locationForm = !!this.itemData.location;
        this.descriptionForm = !!this.itemData.description;
    }

    public addDescription() {
        this.descriptionForm = true;
        this.$emit('description-opened');
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (!this.itemData.end_date) {
            delete this.itemData.end_date;
        }

        return callSuper(this, 'submitForm', e, next);
    }

    @Watch('docked')
    public onDock() {
        this.tab = 'startdata';
    }
}
