

import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import {parseDate, parseDateTime, parseMoment} from '@/shared/helpers';

import TimePicker from '@/shared/components/elements/time-picker.vue';

@Component({
    components: {
        DatePicker,
        TimePicker,
    },
})
export default class DateSection extends Vue {
    @Prop(String) public start!: string;
    @Prop(String) public end!: string;
    @Prop(Boolean) public allDay!: string;

    public startDate: string = '';
    public startTime: string = '';
    public endDate: string = '';
    public endTime: string = '';

    public parseDate = parseDate;
    public parseDateTime = parseDateTime;

    public setStartDate() {
        let date = `${this.startDate} `;

        if (!this.allDay) {
            date += this.startTime;
        } else {
            date += '0:00';
        }

        this.$emit('update:start', date);
    }

    public setEndDate() {
        if (!this.endDate) {
            this.endDate = this.startDate;
        }

        let date = `${this.endDate} `;

        if (!this.allDay) {
            date += this.endTime;
        } else {
            date += '23:59';
        }

        this.$emit('update:end', date);
    }

    public mounted() {
        this.onStartChange();
        this.onEndChange();
    }

    @Watch('start')
    public onStartChange() {
        this.startDate = parseDate(this.start);
        this.startTime = parseDateTime(this.start, 'HH:mm');
    }

    @Watch('end')
    public onEndChange() {
        this.endDate = parseDate(this.end);
        this.endTime = parseDateTime(this.end, 'HH:mm');
    }

    get endAfter() {
        return (v: string) => ((!this.end)
                || (new Date(this.end) >= new Date(this.start)))
            || 'Data zakończenia nie może być wcześniej niż data rozpoczęcia';
    }
}

