
import Vue from 'vue';
import Component from 'vue-class-component';
import { investmentSubjectMediaFilesModule } from '@/modules/investments/shared/state/submodules';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { privateStatuses, publicStatuses } from '@/shared/config/investments';

@Component({
    components: {
        NameReduce,
    },
    props: {
        id: Number,
        name: String,
        code: String,
        area: Number,
        volume: Number,
        public_status: String,
        private_status: String,
        disabled: Boolean,
    },
})
export default class InvestmentSubjectCard extends Vue {
    public mediaActionsTypes = investmentSubjectMediaFilesModule.actionsTypes;
    public fetchMediaAction = this.mediaActionsTypes.FETCH_DATA;

    public tumbnailUrl: string = '';

    get itemPrivateStatus() {
        return privateStatuses.find((status) => status.value === this.$props.private_status);
    }

    get itemPublicStatus() {
        return publicStatuses.find((status) => status.value === this.$props.public_status);
    }

    public created() {
        this.$store.dispatch(this.fetchMediaAction, { id: this.$props.id, simple: true }).then(({ data }) => {
            const mediaFile = data[0]?.media_file;

            if (!mediaFile || !mediaFile.has_thumbnail) { this.tumbnailUrl = '/assets/photo.png';
            } else { this.tumbnailUrl = mediaFile.thumbnail; }
        });
    }
}
