
import Vue from 'vue';
import Component from 'vue-class-component';
import {Ticket, ticketsModule} from '../shared';
import TicketComponent from '@/modules/tickets/components/kanban/ticket.vue';
import {Watch} from 'vue-property-decorator';

@Component({
  components: {
    TicketComponent,
  },
  props: {
    label: String,
    returnObject: Boolean,
    returnId: Boolean,
    customFilters: Object,
    value: [Object, Number],
  },
})
export default class TicketsPicker extends Vue {
  public store = this.$store.state.ticketsState;
  public fetchAction = ticketsModule.actionsTypes.FETCH_DATA;
  public showAction = ticketsModule.actionsTypes.SHOW_ITEM;

  public items = [] as Ticket[];

  // public selectedTicket = {} as Ticket;
  get selectedTicket() {
    return this.store.current;
  }

  set selectedTicket(val) {
    this.store.current = val;
    this.$emit('input', val);
  }

  public showTickets = false;
  public timer: ReturnType<typeof setTimeout> | undefined;
  public isLoading = false;
  public filters: any = this.$props.customFilters ? {...this.$props.customFilters} : {};
  public search: string = '';

  // get selectedTicketData() {
  //     const ticket = this.selectedTicket;
  //     if (!this.selectedTicket) return {};
  //     else {
  //         return {
  //             title: ticket.name,
  //             ...ticket,
  //         };
  //     }
  // }
  @Watch('value')
  public onValueChage(value: any) {
    if (typeof this.$props.value === 'number') {
      this.isLoading = true;
      this.$store.dispatch(this.showAction, value).finally(() => {
        this.isLoading = false;
      });
    } else if (!value) {
      this.store.current = {};
    }
  }

  @Watch('search')
  public sendRequestOnSearch(value: string) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.filters.search = value;
    this.timer = setTimeout(this.fetchData, 300);
  }

  @Watch('showTickets')
  public onShowTickets(value: boolean) {
    this.$nextTick(() => {
      if (value) {
        (this.$refs.search as HTMLElement).focus();
      }
    });
  }

  public fetchData() {
    if (this.isLoading) {
      return;
    }
    this.items = [];
    this.isLoading = true;

    this.$store
        .dispatch(this.fetchAction, {simple: true, filters: this.filters ? this.filters : {}})
        .then(({data}: any) => {
          this.items = data;
          this.isLoading = false;
        });
  }

  public selectTicket(ticket: any) {
    this.showTickets = false;
    // this.selectedTicket = ticket;
    // this.isLoading = true;
    if (this.$props.returnId) {
      this.$emit('input', ticket.id);
    } else {
      this.$emit('input', ticket);
    }
    // this.$store.dispatch(this.showAction, ticket.id).finally(() => {
    //     // this.isLoading = false;
    // });
  }

  public onOverlayClick() {
    this.showTickets = false;
  }

  public mounted() {
    // if (this.$props.value && typeof this.$props.value === 'number') {
    //     this.$store.dispatch(this.showAction, this.$props.value).then((data) => console.log(data));
    // }
    this.fetchData();
  }
}
